import React from 'react'
import { useFirebase } from 'firebase-utils'
import PropTypes from 'prop-types'
import { Alignment, Box, Button } from 'rn-design-system'
import { format } from 'date-fns'
import Empty from 'components/shared/empty'
import { useRoomContext } from 'context/room-context'
import Mix from 'components/shared/mix'
import SpacedText from '../SpacedText'

const MixAdmin = () => {
  const {
    room: { mix },
  } = useRoomContext()

  return (
    <Alignment flexDirection="column" width="100%" alignItems="center">
      {mix.url ? <NowPlaying mix={mix} /> : <Empty>nothing playing</Empty>}
    </Alignment>
  )
}

const NowPlaying = ({ mix }) => {
  const firebase = useFirebase()

  const clear = () => {
    firebase.room.clearNowPlaying()
  }

  return (
    <>
      <Mix mix={mix} />
      <SpacedText>{mix.name}</SpacedText>
      <SpacedText>{mix.url}</SpacedText>
      {mix.startTime && (
        <SpacedText>
          started: {format(new Date(mix.startTime), 'HH:mm | yyyy-mm-dd')} | (
          {mix.startTime})
        </SpacedText>
      )}
      <Box mt={3}>
        <Button onClick={() => clear()}>clear</Button>
      </Box>
    </>
  )
}

NowPlaying.propTypes = {
  mix: PropTypes.shape({
    url: PropTypes.string,
    startTime: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
}

export default MixAdmin
