import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import Link from 'components/generic/link'
import LiveIndicator from '../live-indicator'

const Nav = ({ selectedTab, currentRoom, radioLive, showNav }) => (
  <Alignment
    justifyContent={{ s: 'space-between', m: '' }}
    flexDirection={{ s: 'row', m: 'column' }}
    width="100%"
    mt={{ s: 3, m: 0 }}
  >
    <Box mb={{ s: 0, m: 4 }}>
      <Link textVariant={Text.variants.large} href="/system-admin">
        &lt; back to admin
      </Link>
    </Box>
    {showNav && (
      <>
        <NavLink
          selectedTab={selectedTab}
          roomName={currentRoom}
          showLive={!radioLive}
        />
        <NavLink
          selectedTab={selectedTab}
          roomName={currentRoom}
          tab="radio"
          showLive={radioLive}
        />
      </>
    )}
  </Alignment>
)

Nav.propTypes = {
  showNav: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
  currentRoom: PropTypes.string.isRequired,
  radioLive: PropTypes.bool,
}

Nav.defaultProps = {
  radioLive: false,
}

const NavLink = ({ selectedTab, roomName, tab, showLive }) => (
  <Alignment mb={{ s: 0, m: 3 }} alignItems="flex-end">
    <Link
      textVariant={Text.variants.large}
      href={`/system-admin/rooms/${roomName}/${tab}`}
      underline={selectedTab === tab}
    >
      {tab}
    </Link>
    {showLive && <LiveIndicator ml={2} />}
  </Alignment>
)

NavLink.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  showLive: PropTypes.bool,
  roomName: PropTypes.string.isRequired,
  tab: PropTypes.string,
}

NavLink.defaultProps = {
  showLive: false,
  tab: 'mix',
}

export default Nav
