import { useState, useEffect } from 'react'
import { useFirebase } from 'firebase-utils'
import { useCollection } from 'react-firebase-hooks/firestore'
import hasDocs from 'helpers/hasDocs'
import { useSyncerContext } from 'context/syncer-context'

const usePoll = (onDecision) => {
  const firebase = useFirebase()
  const syncer = useSyncerContext()
  const [upNext] = useCollection(
    firebase.firestore
      .collection('upNext')
      .where('room', '==', firebase.currentRoom)
  )

  const [poll, setPoll] = useState([])

  useEffect(() => {
    if (hasDocs(upNext)) {
      const next = upNext.docs.reduce((a, b) => {
        const data = b.data()

        return [
          ...a,
          {
            id: b.id,
            ...data,
            isSet: !!data.url,
          },
        ]
      }, [])

      setPoll(next)
    }
  }, [upNext, syncer.id])

  const decide = () => {
    const ordered = poll?.sort((a, b) => b.votes.length - a.votes.length)

    const next = ordered?.[0]

    if (next?.url && next?.name) {
      firebase.room.updateNowPlaying(next.url, next.name).then(() => {
        firebase.room.resetUpNext()
        onDecision()
      })
    }
  }

  return [poll, decide]
}

export default usePoll
