import React from 'react'
import styled from 'styled-components'
import { Alignment, Text } from 'rn-design-system'
import { useSettingsContext } from 'context/room-context'

const Footer = () => {
  const { settings, loading } = useSettingsContext()

  if (loading) {
    return null
  }

  return (
    <Alignment justifyContent="center" alignItems="end">
      <Bottom position={{ m: 'fixed' }} textAlign="center" py={3}>
        <ItalicText>{settings?.footer || 'how far'}</ItalicText>
      </Bottom>
    </Alignment>
  )
}

const Bottom = styled(Alignment)`
  bottom: 0;
`

const ItalicText = styled(Text)`
  font-style: italic;
`

export default Footer
