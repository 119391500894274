import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import HiddenForm from 'components/generic/hidden-form'

const LINK_TYPES = {
  SOUNDCLOUD: 'soundcloud',
  INSTAGRAM: 'instagram',
  WEBSITE: 'website',
}

const SocialLinkForm = ({ linkType, linkValue, linkPlaceholder, setLink }) => (
  <>
    <Alignment mt={3}>
      <Text color={linkValue ? undefined : 'tertiary'}>
        {linkValue || linkPlaceholder}
      </Text>
      <Box mx={3}>
        <Text>/</Text>
      </Box>
      <Text>{linkType}</Text>
    </Alignment>
    <Alignment flexDirection={{ s: 'column', m: 'row' }} mt={2}>
      <HiddenForm
        type="url"
        name="link"
        placeholder={`${linkType} link`}
        onSubmit={setLink}
        buttonText={`change ${linkType} link`}
        horizontal
      />
    </Alignment>
  </>
)

SocialLinkForm.propTypes = {
  setLink: PropTypes.func.isRequired,
  linkType: PropTypes.oneOf(Object.values(LINK_TYPES)).isRequired,
  linkValue: PropTypes.string,
  linkPlaceholder: PropTypes.string,
}

SocialLinkForm.defaultProps = {
  linkValue: '',
  linkPlaceholder: '',
}

export { LINK_TYPES }
export default SocialLinkForm
