import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box } from 'rn-design-system'
import TabsHeader from './components/tabs-header'

const getActiveTab = (children, activeTab) => {
  const Tab = children.find((x) => x?.props?.name === activeTab)
  const tabs = children.reduce((acc, tab) => {
    if (!tab?.props?.name) {
      return acc
    }

    return [...acc, tab?.props?.name]
  }, [])

  return { tabs, Tab }
}

const Tabs = ({ defaultTab, children }) => {
  const [tabList, setTabs] = useState([])
  const [activeTab, setActiveTab] = useState(defaultTab)
  const [ActiveTabContent, setActiveTabContent] = useState(null)

  useEffect(() => {
    const { tabs, Tab } = getActiveTab(children, activeTab)

    setTabs(tabs)
    setActiveTabContent(Tab)
  }, [activeTab, children])

  return (
    <Alignment flexDirection="column">
      <TabsHeader
        tabs={tabList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {ActiveTabContent}
    </Alignment>
  )
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  defaultTab: PropTypes.string.isRequired,
}

const Tab = ({ name, children, ...props }) => (
  <Box name={name} {...props}>
    {children}
  </Box>
)

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

Tabs.Tab = Tab
export default Tabs
