const IMAGES = {
  VISUALS1:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2Fvisuals1rotate.gif?alt=media',
  VISUALS2:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2Fvisuals2rotate.gif?alt=media',
  VISUALS3:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2Fsyncers_noise.gif?alt=media',
  RECTANGLE_PULSE:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2Frectangle.gif?alt=media',
  CURSOR:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/images%2Fglobe.png?alt=media',
  LOGO_BLACK_WHITE:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/design%2Flogo-black-white.jpg?alt=media',
  LOGO_WHITE_BLACK:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/design%2Flogo-white-black.jpg?alt=media',
  LOGO_WHITE_TRANSPARENT:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/design%2Flogo-white-transparent.png?alt=media',
  LOGO_BLACK_TRANSPARENT:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/design%2Flogo-black-transparent.png?alt=media',
  POWERED_BY_GIPHY_SMALL:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2Fpowered-by-giphy.gif?alt=media&token=6120e484-0278-4c09-bfed-89a1d9cf2959',
  POWERED_BY_GIPHY:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/gifs%2FPoweredBy_640_Horizontal_Light-Backgrounds_With_Logo.gif?alt=media&token=952ae2c4-6762-4205-87df-d04535d27462',
  TECHNOMATE_CURSOR:
    'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/technomate%2Ftechnomate.png?alt=media&token=5302d911-612d-4c7e-affa-21075fe7518e',
}

export default IMAGES
