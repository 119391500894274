import {
  format,
  startOfDay,
  compareAsc,
  addDays,
  differenceInCalendarDays,
} from 'date-fns'

const resultForSyncer = (syncer, message, result, normal) =>
  message.syncerId === syncer.id ? result : normal

const timestampFormatted = (dateTimeString) => {
  const dateTime = new Date(dateTimeString)
  const currentDateTime = new Date()
  const startOfToday = startOfDay(currentDateTime)

  if (compareAsc(dateTime, startOfToday) === 1) {
    return format(dateTime, 'HH:mm:ss')
  }

  const startOfYesterday = addDays(startOfToday, -1)
  if (compareAsc(dateTime, startOfYesterday) === 1) {
    return 'yesterday'
  }

  return `${differenceInCalendarDays(currentDateTime, dateTime)} days ago`
}

export { resultForSyncer, timestampFormatted }
