import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Alignment, Button } from 'rn-design-system'

const TabsHeader = ({ tabs, activeTab, setActiveTab }) => (
  <Alignment>
    {tabs.map((tab) => (
      <TabButton
        active={activeTab === tab}
        key={tab}
        onClick={() => setActiveTab(tab)}
      >
        {tab}
      </TabButton>
    ))}
  </Alignment>
)

const activeBorder = css`
  border-top: 1px dashed;
  border-left: 1px dashed;
  border-right: 1px dashed;
`

TabsHeader.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

const TabButton = styled(Button)`
  border: none;

  ${({ active }) => active && activeBorder}
`

export default TabsHeader
