import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Alignment, Button } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import Loading from 'components/shared/loading'
import HiddenFormInput from './HiddenFormInput'

const HiddenForm = ({
  type,
  name,
  placeholder,
  onSubmit,
  buttonText,
  horizontal,
  maxLength,
}) => {
  const { error } = useFirebase()
  const [showForm, setShowForm] = useState(false)
  const [formErrors, setFormErrors] = useState()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      await onSubmit(values)

      setShowForm(false)
    } catch (err) {
      setFormErrors(err)
      await error.insert(`error on hidden form: ${err?.message}`)
    }
    setLoading(false)
  }

  return (
    <Alignment flexDirection={horizontal ? 'row' : 'column'}>
      {showForm && (
        <HiddenFormInput
          type={type}
          name={name}
          placeholder={placeholder}
          onSubmit={handleSubmit}
          formErrors={formErrors}
          maxLength={maxLength}
        />
      )}
      <Box
        mt={horizontal || !showForm ? 0 : 3}
        ml={horizontal && showForm ? 3 : 0}
      >
        <Button disabled={loading} onClick={() => setShowForm(!showForm)}>
          {showForm ? (
            'nah'
          ) : (
            <ButtonText text={buttonText} loading={loading} />
          )}
        </Button>
      </Box>
    </Alignment>
  )
}

HiddenForm.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  maxLength: PropTypes.number,
}

HiddenForm.defaultProps = {
  horizontal: false,
  maxLength: 80,
}

const ButtonText = ({ loading, text }) => (loading ? <Loading loading /> : text)

ButtonText.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default HiddenForm
