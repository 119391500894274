import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'rn-design-system'
import styled from 'styled-components'
import BOX_STYLES, { DEFAULT_BOX_STYLE } from './box-styles'

const getMargin = (index) => {
  if (index === 1) {
    return 2
  }

  return index > 3 ? 2 : 3
}

const QueueItemName = ({ name, index }) => {
  const config = BOX_STYLES[index] || DEFAULT_BOX_STYLE

  return (
    <RaisedBox config={config} mt={getMargin(index)} p={2} border="2px solid">
      <BorderBox config={config} />
      <Text>{name}</Text>
    </RaisedBox>
  )
}

QueueItemName.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({}),
}

QueueItemName.defaultProps = {
  config: {},
}

const RaisedBox = styled(Box)`
  position: relative;
  top: ${({ config }) => config.offset}px;
  left: ${({ config }) => config.offset}px;
  border-color: ${({ theme }) => theme.colors.primary};

  &::after {
    position: absolute;
    content: '';
    width: 2px;
    height: ${({ config }) => config.borders.leftBottom.height}px;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.colors.primary};
    left: ${({ config }) => config.borders.leftBottom.left}px;
    bottom: ${({ config }) => config.borders.leftBottom.bottom}px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: ${({ config }) => config.borders.leftTop.height}px;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.colors.primary};
    left: ${({ config }) => config.borders.leftTop.left}px;
    top: ${({ config }) => config.borders.leftTop.top}px;
  }
`

const BorderBox = styled(Box)`
  position: absolute;
  width: 2px;
  height: ${({ config }) => config.borders.rightTop.height}px;
  transform: rotate(-45deg);
  background-color: ${({ theme }) => theme.colors.primary};
  top: ${({ config }) => config.borders.rightTop.top}px;
  right: ${({ config }) => config.borders.rightTop.right}px;
`

export default QueueItemName
