import { useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import { useHeadingContext, actions } from 'context/heading-context'

const useSettings = (roomName) => {
  const firebase = useFirebase()
  const { dispatch } = useHeadingContext()

  const [document, loading] = useDocument(
    firebase.firestore.doc(`settings/${roomName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  const [settings, setSettings] = useState({
    exists: false,
  })

  useEffect(() => {
    if (!loading) {
      firebase.setRoom(roomName)
      if (document.exists) {
        const data = document.data()
        dispatch({
          type: actions.SET_HEADING,
          payload: {
            live: data?.switches?.streamLive,
            headerFont: data?.pageStyle?.headerFont,
          },
        })

        setSettings({
          exists: true,
          ...data,
        })
      }
    }
  }, [document, setSettings, roomName, firebase, loading, dispatch])

  return { loading, settings }
}

export default useSettings
