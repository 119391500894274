import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Text, Alignment, Box, Button } from 'rn-design-system'
import useRequest, {
  REQUEST_METHODS,
} from 'firebase-utils/server-access/useRequest'
import Loading from 'components/shared/loading'
import chunk from 'lodash/chunk'
import HiddenForm from 'components/generic/hidden-form'
import { useFirebase } from 'firebase-utils'
import User from './User'

const AddUser = ({ accessType }) => {
  const createUser = useRequest('/users/upsert-room-user')
  const [loading, setLoading] = useState(false)
  const { error } = useFirebase()

  const create = async (values) => {
    if (values?.email) {
      try {
        setLoading(true)
        await createUser({
          email: values.email,
          accessType,
        })
      } catch (err) {
        await error.insert(`error adding user: ${err?.message}`)
      }
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading my={2} loading />
  }

  return (
    <HiddenForm
      type="email"
      name="email"
      placeholder="email"
      onSubmit={create}
      buttonText="+"
      horizontal
    />
  )
}

AddUser.propTypes = {
  accessType: PropTypes.string.isRequired,
}

const RoomAdminUsersList = ({ title, accessType, userIds, pageSize }) => {
  const updateUser = useRequest('/users/update-user', REQUEST_METHODS.PUT)
  const [pageNumber, setPageNumber] = useState(0)
  const [chunks, setChunks] = useState([])

  useEffect(() => {
    const result = chunk(userIds, pageSize)?.reduce(
      (acc, userIdsChunk, index) => [
        ...acc,
        {
          show: index <= pageNumber,
          userIds: userIdsChunk,
        },
      ],
      []
    )

    setChunks(result)
  }, [pageNumber, pageSize, userIds])

  return (
    <Alignment flexDirection="column" justifyContent="center" my={3}>
      <Alignment alignItems="center">
        <Text variant={Text.variants.large}>{title}</Text>
        <Box ml={2}>
          <AddUser accessType={accessType} />
        </Box>
      </Alignment>
      {chunks
        ?.filter((x) => x.show)
        ?.map((x, index) => (
          <UsersChunk
            key={index}
            accessType={accessType}
            updateUser={updateUser}
            userIds={x.userIds}
          />
        ))}
      {chunks?.some((x) => !x.show) && (
        <Alignment mt={2}>
          <Button onClick={() => setPageNumber((page) => page + 1)}>
            more
          </Button>
        </Alignment>
      )}
    </Alignment>
  )
}

RoomAdminUsersList.propTypes = {
  title: PropTypes.string.isRequired,
  accessType: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  pageSize: PropTypes.number,
}

RoomAdminUsersList.defaultProps = {
  pageSize: 5,
}

const UsersChunk = ({ userIds, accessType, updateUser }) => {
  const [loading, setLoading] = useState(true)

  const getUsers = useRequest(
    `/users/get-users?uids=${userIds.join(';')}`,
    REQUEST_METHODS.GET
  )
  const [result, setResult] = useState({
    data: {},
  })

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      const response = await getUsers()

      setResult(response)
      setLoading(false)
    }

    get()
  }, [getUsers])

  if (loading) {
    return userIds.map((uid) => <Loading my={2} loading key={uid} />)
  }

  if (result?.error) {
    return null
  }

  return result?.data.map((user) => (
    <User
      key={user.uid}
      user={user}
      accessType={accessType}
      updateUser={updateUser}
    />
  ))
}

UsersChunk.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateUser: PropTypes.func.isRequired,
  accessType: PropTypes.string.isRequired,
}

export default RoomAdminUsersList
