import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import Switch from 'react-switch'
import styled, { useTheme } from 'styled-components'
import { useFirebase } from 'firebase-utils'
import { border } from 'styled-system'

const ToggleHistoryClickable = ({ historyClickable }) => {
  const { settings, error } = useFirebase()
  const theme = useTheme()

  const handleChange = async (value) => {
    try {
      await settings.update({ 'switches.historyClickable': value })
    } catch (err) {
      await error.insert(`error setting history ticker: ${err?.message}`)
    }
  }

  return (
    <Alignment alignItems="center">
      <Box mr={2}>
        <Text variant={Text.variants.large}>history links clickable:</Text>
      </Box>
      <StyledSwitch
        {...theme.switch}
        onChange={handleChange}
        checked={historyClickable}
      />
    </Alignment>
  )
}

ToggleHistoryClickable.propTypes = {
  historyClickable: PropTypes.bool.isRequired,
}

const StyledSwitch = styled(Switch)`
  ${border};
`

export default ToggleHistoryClickable
