import React from 'react'
import PropTypes from 'prop-types'
import { Text, Alignment, Box } from 'rn-design-system'
import Link from 'components/generic/link'
import useSyncingCount from 'hooks/useSyncingCount'
import { getRoomName } from 'helpers/getRoom'
import useTextRandomizer from 'hooks/useTextRandomizer'
import styled from 'styled-components'

const isHome = (name) => name === 'home'

const Title = ({ name, link, headerFont }) => {
  const syncingCount = useSyncingCount()
  const title = useTextRandomizer('syncers worldwide')

  return (
    <Alignment flexDirection="column">
      <Alignment>
        <Box display={{ s: 'none', m: 'block' }}>
          <StyledLink
            font={headerFont}
            href="/"
            textVariant={Text.variants.xLarge}
          >
            {title}
          </StyledLink>
        </Box>
        <Box display={{ m: 'none' }}>
          <Link href="/" textVariant={Text.variants.large}>
            {title}
          </Link>
        </Box>
        {name && !isHome(name) && <Link href={link}>{getRoomName(name)}</Link>}
      </Alignment>
      <Text>
        you&apos;re syncing with {syncingCount}&nbsp;
        {syncingCount === 1 ? 'person' : 'people'}
      </Text>
    </Alignment>
  )
}

Title.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  headerFont: PropTypes.string,
}

Title.defaultProps = {
  name: null,
  link: null,
  headerFont: null,
}

const StyledLink = styled(Link)`
  font-family: ${({ font }) => font};
`

export default Title
