import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import hasDocs from 'helpers/hasDocs'
import { Text, Alignment, Box, Button } from 'rn-design-system'
import Link from 'components/generic/link'
import { getRoomName } from 'helpers/getRoom'
import ToggleRoomActive from './ToggleRoomActive'
import ToggleRoomFeatured from './ToggleRoomFeatured'
import DeleteRoom from './DeleteRoom'

const formatRooms = (docs) => {
  const reduced = docs.reduce((a, room) => {
    const data = room.data()

    return [
      ...a,
      {
        id: room.id,
        name: data.name,
        active: data.active,
        featured: data.featured,
        url: room.id === 'home' ? '/' : `/rooms/${room.id}`,
        adminUrl: `/system-admin/rooms/${room.id}`,
      },
    ]
  }, [])

  return reduced
}

const Rooms = ({ limit, usePagination }) => {
  const { firestore, featureSwitches } = useFirebase()
  const [collection] = useCollection(
    firestore
      .collection('rooms')
      .orderBy('active', 'desc')
      .orderBy('name')
      .limit(limit)
  )
  const [rooms, setRooms] = useState([])
  const [lastRoom, setLastRoom] = useState('')
  const [canPaginate, setCanPaginate] = useState(false)

  const setRoomsList = useCallback(
    (docs, merge) => {
      const reduced = formatRooms(docs)

      if (reduced.length > 0) {
        const last = reduced.slice(-1)[0]
        setLastRoom(last.id)
      }
      setCanPaginate(docs.length === limit)

      if (merge) {
        setRooms((x) => [...x, ...reduced])
      } else {
        setRooms(reduced)
      }
    },
    [setLastRoom, setCanPaginate, setRooms, limit]
  )

  useEffect(() => {
    if (hasDocs(collection)) {
      setRoomsList(collection.docs)
    }
  }, [collection, setRoomsList, limit])

  const getNext = () => {
    firestore
      .collection('rooms')
      .orderBy('name')
      .startAfter(lastRoom)
      .limit(limit)
      .get()
      .then((result) => {
        if (result.empty) {
          setCanPaginate(false)
        } else {
          setRoomsList(result.docs, true)
        }
      })
  }

  return (
    <Alignment flexDirection="column">
      {rooms.map((room) => (
        <Alignment
          justifyContent="space-between"
          key={room.id}
          mt={2}
          borderBottom="1px dashed"
          p={1}
        >
          <Alignment>
            <Link underline href={room.url} textVariant={Text.variants.large}>
              {getRoomName(room.name)}
              {room.featured ? ' (f)' : ''}
            </Link>
            <Text variant={Text.variants.large}>&nbsp;/&nbsp;</Text>
            <Link
              underline
              href={room.adminUrl}
              textVariant={Text.variants.large}
            >
              admin
            </Link>
          </Alignment>
          <Alignment>
            {featureSwitches.enableDeleting && (
              <Box mr={2}>
                <DeleteRoom roomName={room.id} />
              </Box>
            )}
            <ToggleRoomActive roomName={room.id} active={room.active} />
            <Box ml={2}>
              <ToggleRoomFeatured roomName={room.id} featured={room.featured} />
            </Box>
          </Alignment>
        </Alignment>
      ))}
      {usePagination && canPaginate && (
        <Box mt={3}>
          <Button onClick={() => getNext()}>+ more</Button>
        </Box>
      )}
    </Alignment>
  )
}

Rooms.propTypes = {
  limit: PropTypes.number,
  usePagination: PropTypes.bool,
}

Rooms.defaultProps = {
  limit: 10,
  usePagination: false,
}

export default Rooms
