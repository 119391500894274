import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import Switch from 'react-switch'
import styled, { useTheme } from 'styled-components'
import { useFirebase } from 'firebase-utils'
import { border } from 'styled-system'

const TogglePollQueue = ({ queueLive }) => {
  const { settings, error } = useFirebase()
  const theme = useTheme()

  const handleChange = async (value) => {
    try {
      const switches = {
        'switches.queueLive': value,
      }

      await settings.update(switches)
    } catch (err) {
      await error.insert(`error setting stream live: ${err?.message}`)
    }
  }

  return (
    <Alignment alignItems="center" mt={3}>
      <Box mr={2}>
        <Text variant={Text.variants.large}>poll</Text>
      </Box>
      <StyledSwitch
        {...theme.switch}
        onChange={handleChange}
        checked={queueLive}
        uncheckedIcon={false}
        checkedIcon={false}
      />
      <Box ml={2}>
        <Text variant={Text.variants.large}>queue</Text>
      </Box>
    </Alignment>
  )
}

TogglePollQueue.propTypes = {
  queueLive: PropTypes.bool.isRequired,
}

const StyledSwitch = styled(Switch)`
  ${border};
`

export default TogglePollQueue
