import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from 'rn-design-system'

const sGTA = `
  "nav"
  "content"
`

const mGTA = `
  "nav content content content content content content content"
`

const Areas = {
  Nav: 'nav',
  Content: 'content',
}

const SystemAdminLayout = ({ children }) => (
  <Box m={{ s: 1, m: 4 }}>
    <Grid
      sCols={1}
      sGTA={sGTA}
      mCols={7}
      mGTA={mGTA}
      width="100%"
      height="100%"
    >
      {children}
    </Grid>
  </Box>
)

SystemAdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { SystemAdminLayout, Areas }
