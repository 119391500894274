import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Alignment } from 'rn-design-system'
import Poll from 'components/shared/poll'
import Queue from 'components/shared/queue'
import styled from 'styled-components'
import { useFirebase } from 'firebase-utils'
import Empty from 'components/shared/empty'
import { getRoomName } from 'helpers/getRoom'
import Link from 'components/generic/link'
import Player from './Player'

const TextAligned = styled(Text)`
  text-align: center;
`

const TextAlignUnderline = styled(Text)`
  text-align: center;
  text-decoration: underline;
`

const BackgroundBox = styled(Box)`
  background: url('https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/room-files%2Fmagnetic-fields-festival%2Fmf-gif.gif?alt=media');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const RoomTakeover = ({ roomName }) => (
  <Empty p={{ s: 2, m: 5 }}>
    <Alignment flexDirection="column" alignItems="center">
      <TextAligned variant={Text.variants.xLarge}>
        today we have something special on over at
      </TextAligned>
      <Link underline href={`/rooms/${roomName}`}>
        <TextAlignUnderline variant={Text.variants.xLarge}>
          {getRoomName(roomName)}&nbsp;&rarr;
        </TextAlignUnderline>
      </Link>
      <BackgroundBox mt={2} p={4} />
      <Text>enjoi</Text>
    </Alignment>
  </Empty>
)

RoomTakeover.propTypes = {
  roomName: PropTypes.string.isRequired,
}

const Center = ({ currentRoom, queueLive }) => {
  const { featureSwitches } = useFirebase()

  if (
    featureSwitches?.roomTakeover &&
    currentRoom !== featureSwitches?.roomTakeover
  ) {
    return <RoomTakeover roomName={featureSwitches?.roomTakeover} />
  }

  return queueLive ? (
    <Queue />
  ) : (
    <>
      <Player />
      <Box mt={3} width="100%">
        <Poll />
      </Box>
    </>
  )
}

Center.propTypes = {
  currentRoom: PropTypes.string.isRequired,
  queueLive: PropTypes.bool.isRequired,
}

export default Center
