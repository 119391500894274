import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Button } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import { useSyncerContext } from 'context/syncer-context'

const UpNextItem = ({ id, name, votes, voted }) => {
  const { room } = useFirebase()
  const syncer = useSyncerContext()

  const toggleVote = () => {
    if (votes.includes(syncer.id)) {
      room.unvote(id, syncer.id)
    } else {
      room.vote(id, syncer.id)
    }
  }

  return (
    <Alignment>
      <Alignment px={3} alignItems="center" border="1px dashed">
        <Text>{name}</Text>
      </Alignment>
      <Alignment
        px={3}
        alignItems="center"
        borderTop="1px dashed"
        borderBottom="1px dashed"
      >
        <Text>{votes.length}</Text>
      </Alignment>
      <Button
        disabled={voted && !votes.includes(syncer.id)}
        onClick={() => toggleVote()}
      >
        {votes.includes(syncer.id) ? 'unvote' : 'vote'}
      </Button>
    </Alignment>
  )
}

UpNextItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  votes: PropTypes.arrayOf(PropTypes.string).isRequired,
  voted: PropTypes.bool.isRequired,
}

export default UpNextItem
