import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Grid, Box } from 'rn-design-system'
import { SystemAdminAuthenticatedComponent } from 'context/user-context'
import { SystemAdminLayout, Areas } from './components/system-admin-layout'
import Heading from './components/heading'
import Nav from './components/nav'
import RoomsList from './components/rooms-list'

const TABS = {
  ROOMS: 'rooms',
}

const getTab = (tab) => (Object.values(TABS).includes(tab) ? tab : 'rooms')

const SystemAdmin = () => {
  const { tab } = useParams()

  return (
    <SystemAdminAuthenticatedComponent>
      <Heading />
      <SystemAdminContent tab={getTab(tab)} />
    </SystemAdminAuthenticatedComponent>
  )
}

const SystemAdminContent = ({ tab }) => (
  <SystemAdminLayout>
    <Grid.Area area={Areas.Nav}>
      <Nav selectedTab={tab} />
    </Grid.Area>
    <Grid.Area alignSelf="stretch" area={Areas.Content}>
      <Tabs tab={tab} />
    </Grid.Area>
  </SystemAdminLayout>
)

SystemAdminContent.propTypes = {
  tab: PropTypes.string.isRequired,
}

const Tabs = ({ tab }) => (
  <Box ml={2}>{tab === TABS.ROOMS && <RoomsList />}</Box>
)

Tabs.propTypes = {
  tab: PropTypes.string.isRequired,
}

export default SystemAdmin
