class Storage {
  #FOLDER = 'room-files'

  constructor(storage, currentRoom) {
    this.storage = storage
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  listPostItems = async () => {
    const ref = this.storage.ref()
    const listRef = ref.child(`${this.#FOLDER}/${this.currentRoom}`)

    const result = await listRef.listAll()

    const response = await Promise.all(
      result.items.map(async (item) => {
        const url = await item.getDownloadURL()

        return url.slice(0, url.lastIndexOf('&'))
      })
    )

    return response
  }

  #getFileName = (blobOrFile, folder) => {
    const base = `${this.#FOLDER}/${this.currentRoom}`

    if (folder && typeof folder === 'string') {
      return `${base}/${folder}/${blobOrFile.name}`
    }

    return `${base}/${blobOrFile.name}`
  }

  uploadBlobOrFile = async (blobOrFile, onComplete, folder) => {
    const ref = this.storage.ref()

    const fileName = this.#getFileName(blobOrFile, folder)

    const imageRef = ref.child(fileName)

    const task = imageRef.put(blobOrFile)

    task.on(
      'state_changed',
      function progress() {},
      function error(err) {
        console.log('Something went wrong', err)
      },
      async function complete() {
        if (onComplete && typeof onComplete === 'function') {
          const url = await imageRef.getDownloadURL()

          onComplete(url)
        }
      }
    )
  }

  deleteBlobOrFile = async (url) => {
    const ref = this.storage.refFromURL(url)

    if (ref) {
      await ref.delete()
    }
  }
}

export default Storage
