import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alignment, TextArea, Button, Text } from 'rn-design-system'
import { useForm } from 'react-hook-form'
import { useFirebase } from 'firebase-utils'
import Loading from 'components/shared/loading'

const DescriptionSettingsForm = ({ description }) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      description,
    },
  })
  const { error, settings } = useFirebase()
  const [showForm, setShowForm] = useState(false)
  const [formErrors, setFormErrors] = useState()
  const [loading, setLoading] = useState(false)

  const onSubmit = async ({ description: value }) => {
    setLoading(true)
    try {
      await settings.update({
        description: value,
      })

      setShowForm(false)
    } catch (err) {
      setFormErrors(err)
      await error.insert(`error on description form: ${err?.message}`)
    }
    setLoading(false)
  }

  return showForm ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alignment flexDirection="column" width="100%">
        <Alignment
          borderLeft="1px dashed"
          borderTop="1px dashed"
          flexDirection="column"
          width="100%"
        >
          <TextArea
            required
            type="text"
            placeholder="description"
            name="description"
            ref={register({
              required: true,
              maxLength: 300,
            })}
            textVariant={Text.variants.medium}
            p={1}
            autoComplete="off"
            autoFocus
          />
        </Alignment>
        <Alignment mt={1}>
          <Button textVariant={Text.variants.large} type="submit" underline>
            {loading ? <Loading loading /> : 'set'}
          </Button>
          {!loading && (
            <Button
              ml={2}
              disabled={loading}
              onClick={() => setShowForm(false)}
            >
              nah
            </Button>
          )}
        </Alignment>
      </Alignment>
      {(errors.email || errors.password || formErrors) && (
        <Text>something went wrong, please try again</Text>
      )}
    </form>
  ) : (
    <Alignment>
      <Button onClick={() => setShowForm(true)}>
        {description ? 'update' : 'set'}
      </Button>
    </Alignment>
  )
}

DescriptionSettingsForm.propTypes = {
  description: PropTypes.string,
}

DescriptionSettingsForm.defaultProps = {
  description: '',
}

const ButtonText = ({ loading, text }) => (loading ? <Loading loading /> : text)

ButtonText.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default DescriptionSettingsForm
