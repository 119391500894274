import React from 'react'
import PropTypes from 'prop-types'
import { Text, Alignment, Box } from 'rn-design-system'
import EditableInput from 'components/generic/editable-input/EditableInput'
import SendPasswordEmail from './send-password-email'
import RemoveUser from './remove-user'

const User = ({ user, accessType, updateUser }) => {
  const updateName = async (value) => {
    await updateUser({ uid: user?.uid, displayName: value })
  }

  return (
    <Alignment justifyContent="space-between" alignItems="center">
      <Alignment>
        <SpacedElement>
          <Text>{user?.email}</Text>
        </SpacedElement>
        <SpacedElement>
          <Text>/</Text>
        </SpacedElement>
        <EditableInput
          value={user?.displayName}
          onComplete={updateName}
          placeholder="their name"
        />
      </Alignment>
      <Alignment>
        <RemoveUser uid={user?.uid} accessType={accessType} />
        <SendPasswordEmail email={user?.email} />
      </Alignment>
    </Alignment>
  )
}

User.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    displayName: PropTypes.string,
    uid: PropTypes.string,
    admin: PropTypes.bool,
  }).isRequired,
  accessType: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
}

const SpacedElement = ({ children, ...props }) => (
  <Box mr={2} {...props}>
    {children}
  </Box>
)

SpacedElement.propTypes = {
  children: PropTypes.node.isRequired,
}

export default User
