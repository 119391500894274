import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import Switch from 'react-switch'
import styled, { useTheme } from 'styled-components'
import { useFirebase } from 'firebase-utils'
import { border } from 'styled-system'

const ToggleChat = ({ showChat }) => {
  const { settings, error } = useFirebase()
  const theme = useTheme()

  const handleChange = async (value) => {
    try {
      await settings.update({ 'switches.showChat': value })
    } catch (err) {
      await error.insert(`error setting chat live: ${err?.message}`)
    }
  }

  return (
    <Alignment alignItems="center" mt={3}>
      <Box mr={2}>
        <Text variant={Text.variants.large}>chat on:</Text>
      </Box>
      <StyledSwitch
        {...theme.switch}
        onChange={handleChange}
        checked={showChat}
      />
    </Alignment>
  )
}

ToggleChat.propTypes = {
  showChat: PropTypes.bool,
}

ToggleChat.defaultProps = {
  showChat: false,
}

const StyledSwitch = styled(Switch)`
  ${border};
`

export default ToggleChat
