import { v4 as uuidv4 } from 'uuid'

const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz'

const randomUsername = (length) => {
  let result = ''
  for (let i = length; i > 0; i -= 1)
    result += CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)]
  return `anon-${result}`
}

const COLORS = [
  '#0074D9',
  '#7FDBFF',
  '#39CCCC',
  '#3D9970',
  '#2ECC40',
  '#01FF70',
  '#FFDC00',
  '#FF851B',
  '#FF4136',
  '#85144b',
  '#F012BE',
  '#B10DC9',
]

const getRandomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)]

const getRandomSyncer = (syncerId) => {
  const name = randomUsername(4)
  const color = getRandomColor()
  const id = syncerId || uuidv4()

  return {
    id,
    name,
    color,
  }
}

export default getRandomSyncer
