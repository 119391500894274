import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import styled, { useTheme } from 'styled-components'
import { useFirebase } from 'firebase-utils'
import { border } from 'styled-system'

const ToggleReaction = ({ id, live }) => {
  const { reaction, error } = useFirebase()
  const theme = useTheme()

  const handleChange = async (value) => {
    try {
      await reaction.update(id, { live: value })
    } catch (err) {
      await error.insert(
        `error setting reaction ${id} ${value}: ${err?.message}`
      )
    }
  }

  return (
    <StyledSwitch
      {...theme.switch}
      height={16}
      handleDiameter={14}
      onChange={handleChange}
      checked={live}
    />
  )
}

ToggleReaction.propTypes = {
  id: PropTypes.string.isRequired,
  live: PropTypes.bool.isRequired,
}

const StyledSwitch = styled(Switch)`
  ${border};
`

export default ToggleReaction
