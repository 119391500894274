import React from 'react'
import { Text, Box} from 'rn-design-system'

const NothingPlayed = () => (
  <Box m={2}>
    <Text>nothing played, get something on</Text>
  </Box>
)

export default NothingPlayed
