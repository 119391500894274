import { useState, useEffect } from 'react'
import { useFirebase } from 'firebase-utils'
import { useCollection } from 'react-firebase-hooks/firestore'
import hasDocs from 'helpers/hasDocs'

const useReactionsCollection = (activeResults = [true]) => {
  const firebase = useFirebase()
  const [reactions, setReactions] = useState([])
  const [reactionsCollection, loading] = useCollection(
    firebase.firestore
      .collection('reactions')
      .where('room', '==', firebase.currentRoom)
      .where('live', 'in', activeResults)
      .limit(8),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (!loading && hasDocs(reactionsCollection)) {
      const result = reactionsCollection.docs
        .reduce(
          (a, b) => [
            ...a,
            {
              id: b.id,
              ...b.data(),
              activate: firebase.reaction.activate(b.id),
            },
          ],
          []
        )
        .sort((a, b) => a.createdDate - b.createdDate)

      setReactions(result)
    }
  }, [reactionsCollection, loading, firebase])

  return { reactions, loading }
}

export default useReactionsCollection
