class Queue {
  #defaultQueue = []

  #collection = 'queues'

  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  update = async (update) => {
    const document = await this.firestore
      .collection(this.#collection)
      .doc(this.currentRoom)

    return document.update(update)
  }

  init = async (roomName) => {
    const batch = this.firestore.batch()

    const document = await this.firestore
      .collection(this.#collection)
      .doc(roomName)

    batch.set(document, { mixes: this.#defaultQueue }, { merge: true })

    const room = await this.firestore.collection('rooms').doc(roomName)

    batch.set(room, { queue: document }, { merge: true })

    return batch.commit()
  }

  delete = async (roomName) => {
    await this.firestore.collection(this.#collection).doc(roomName).delete()
  }
}
export default Queue
