import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import useAnonymousSyncer from 'hooks/useAnonymousSyncer'

const SyncerContext = createContext()

const SyncerProvider = ({ children }) => {
  const syncer = useAnonymousSyncer()

  return (
    <SyncerContext.Provider value={syncer}>{children}</SyncerContext.Provider>
  )
}

SyncerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSyncerContext = () => useContext(SyncerContext)

export { SyncerProvider, useSyncerContext }
