import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Text, Alignment } from 'rn-design-system'

const Stream = ({ url, name }) => (
  <Box width="100%">
    <Box position="relative">
      {url ? (
        <iframe src={url} height="350px" width="100%" title="radio" />
      ) : (
        <Box border="1px dashed" height="350px" />
      )}
      <Overlay alignItems="center" justifyContent="center">
        <Text variant={Text.variants.large}>{name ? `${name} ` : ''}</Text>
      </Overlay>
    </Box>
  </Box>
)

Stream.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
}

Stream.defaultProps = {
  url: '',
  name: '',
}

const Overlay = styled(Alignment)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export default Stream
