import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text, Button } from 'rn-design-system'
import Dropzone from 'components/generic/dropzone'
import Image from 'components/generic/image'
import DoubleCheckButton from 'components/generic/double-check-button/DoubleCheckButton'

const LinkForm = ({ value, description, setLink, reset }) => {
  const [change, setChange] = useState(false)

  return (
    <Alignment flexDirection="column">
      <Box mb={2}>
        <Text>{description}</Text>
      </Box>

      {value && !change ? (
        <Alignment border="1px dashed" justifyContent="center" py={2} px={1}>
          <Image src={value} />
        </Alignment>
      ) : (
        <Dropzone
          onComplete={(link) => {
            setLink({ link })
            setChange(false)
          }}
          folder="visuals"
        />
      )}
      {value && (
        <Alignment mt={2} flexDirection="column">
          <Button onClick={() => setChange(!change)}>
            {change ? 'nah' : 'change'}
          </Button>
        </Alignment>
      )}
      {value && (
        <Alignment flexDirection="column">
          <DoubleCheckButton buttonText="reset" onSubmit={reset} />
        </Alignment>
      )}
    </Alignment>
  )
}

LinkForm.propTypes = {
  setLink: PropTypes.func.isRequired,
  value: PropTypes.string,
  description: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
}

LinkForm.defaultProps = {
  value: '',
}

export default LinkForm
