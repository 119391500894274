import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Alignment, Box, Text } from 'rn-design-system'
import Loading from 'components/shared/loading'

const DoubleCheckButton = ({ buttonText, onSubmit, buttonVariant }) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    setShow(false)
    await onSubmit()
    setLoading(false)
  }

  return (
    <>
      {!show ? (
        <Button
          disabled={loading}
          onClick={() => setShow(true)}
          variant={buttonVariant}
        >
          <Text>{loading ? <Loading loading /> : buttonText}</Text>
        </Button>
      ) : (
        <Alignment alignItems="flex-end">
          <Box mr={2}>
            <Text>you sure?</Text>
          </Box>
          <Box mr={2}>
            <Button onClick={() => setShow(false)}>
              <Text>nah</Text>
            </Button>
          </Box>
          <Button onClick={handleSubmit}>
            <Text>do it</Text>
          </Button>
        </Alignment>
      )}
    </>
  )
}

DoubleCheckButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.node.isRequired,
  buttonVariant: PropTypes.string,
}

DoubleCheckButton.defaultProps = {
  buttonVariant: undefined,
}

export default DoubleCheckButton
