import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import { isEmpty } from 'lodash'
import useQueue from 'hooks/useQueue'
import useQueueItem from 'hooks/useQueueItem'
import Empty from 'components/shared/empty'
import { addSeconds, isAfter } from 'date-fns'
import useRequest from 'firebase-utils/server-access/useRequest'
import { useRoomAdminContext } from 'context/room-admin-context'
import DoubleCheckButton from 'components/generic/double-check-button'
import { useSettingsContext } from 'context/room-context'
import { Play } from '@styled-icons/fa-solid'
import Mix from '../mix'
import QueueControls from './QueueControls'
import QueueItemName from './queue-item-name'

const MAX_QUEUE = 15

const Queue = () => {
  const { queue, loading } = useQueue()
  const {
    settings: { curated },
  } = useSettingsContext()
  const { isCurator } = useRoomAdminContext()
  const playFirst = useRequest('/rooms/play-first-in-queue')

  if (loading) {
    return null
  }

  if (isEmpty(queue)) {
    return (
      <>
        <Empty>
          <>
            get something on&nbsp;
            <span role="img" aria-label="point down emoji">
              👇
            </span>
          </>
        </Empty>
        <QueueControls />
      </>
    )
  }

  return (
    <Alignment flexDirection="column" alignItems="center" mt={3} width="100%">
      {queue.map((item, index) => (
        <QueueItem
          key={item.id}
          id={item.id}
          index={index}
          firstQueueId={queue[0].id}
          hideAdd={queue.length - 1 === MAX_QUEUE}
          playFirst={playFirst}
          canPlay={!curated || isCurator}
        />
      ))}
    </Alignment>
  )
}

const QueueItem = ({
  id,
  index,
  firstQueueId,
  hideAdd,
  playFirst,
  canPlay,
}) => {
  const { queueItem, loading } = useQueueItem(id)

  if (loading || !queueItem) {
    return null
  }

  const isPlaying = isAfter(
    addSeconds(queueItem.startTime, queueItem.duration),
    Date.now()
  )

  return (
    <>
      {index === 0 && !isPlaying && (
        <Empty>
          {canPlay ? (
            <Alignment flexDirection="column" alignItems="center">
              <Text variant={Text.variants.large}>start the queue</Text>
              <Box mt={2}>
                <DoubleCheckButton
                  onSubmit={playFirst}
                  buttonText={<Play size={20} />}
                  buttonVariant="visuals"
                />
              </Box>
            </Alignment>
          ) : (
            'syncers worldwide'
          )}
        </Empty>
      )}
      {!isPlaying && queueItem?.name && (
        <QueueItemName index={index} name={queueItem.name} />
      )}
      <Mix mix={queueItem} hidden={index !== 0 || !isPlaying} />
      {index === 0 && isPlaying && (
        <QueueControls hideAdd={hideAdd} firstQueueId={firstQueueId} />
      )}
    </>
  )
}

QueueItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  firstQueueId: PropTypes.string.isRequired,
  hideAdd: PropTypes.bool.isRequired,
  playFirst: PropTypes.func.isRequired,
  canPlay: PropTypes.bool.isRequired,
}

export default Queue
