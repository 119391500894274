import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { differenceInSeconds } from 'date-fns'
import { useMixContext, actions } from 'context/mix-context'

const MixcloudMix = ({ mix }) => {
  const [duration, setDuration] = useState()
  const [ready, setReady] = useState(false)

  const ref = useRef()
  const { dispatch } = useMixContext()

  const setHasFinished = useCallback(
    (value) => {
      dispatch({
        type: actions.SET_HAS_FINISHED,
        payload: value,
      })
    },
    [dispatch]
  )

  const setIsPlaying = useCallback(
    (value) => {
      dispatch({
        type: actions.SET_IS_PLAYING,
        payload: value,
      })
    },
    [dispatch]
  )

  const handleDuration = useCallback((seconds) => {
    setDuration(seconds)
  }, [])

  const setTime = useCallback(() => {
    if (ref && ref.current && mix && mix.startTime) {
      const { startTime } = mix
      const timeElapsed = differenceInSeconds(Date.now(), new Date(startTime))

      const finished = timeElapsed >= duration

      const seconds = finished ? duration : timeElapsed
      ref.current.seekTo(seconds, 'seconds')

      setIsPlaying(!finished)
      setHasFinished(finished)
    }
  }, [duration, mix, setIsPlaying, setHasFinished])

  useEffect(() => {
    if (ready && duration) {
      setTime()
    }
  }, [ready, duration, setTime])

  return (
    <ReactPlayer
      ref={ref}
      url={mix.url}
      playing
      onReady={() => setReady(true)}
      onDuration={handleDuration}
      onEnded={() => setHasFinished(true)}
      width="100%"
      height="300px"
      config={{
        options: {
          single_active: false,
        },
      }}
    />
  )
}

MixcloudMix.propTypes = {
  mix: PropTypes.shape({
    url: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
}

export default MixcloudMix
