import { useEffect, useState } from 'react'
import { useFirebase } from 'firebase-utils'

const useFiles = () => {
  const { storage, error: firebaseError } = useFirebase()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  useEffect(() => {
    const getFiles = async () => {
      setLoading(true)
      try {
        const result = await storage.listPostItems()

        setFiles(result)
      } catch (err) {
        setError(err.message)
        await firebaseError.insert(
          `Something went wrong fetching files, ${err.message}`
        )
      }
      setLoading(false)
    }

    getFiles()
  }, [storage, setLoading, setError, firebaseError])

  const refetch = async () => {
    setLoading(true)
    try {
      const result = await storage.listPostItems()

      setFiles(result)
    } catch (err) {
      setError(err.message)
      await firebaseError.insert(
        `Something went wrong fetching files, ${err.message}`
      )
    }
    setLoading(false)
  }

  return { error, loading, files, refetch }
}

export default useFiles
