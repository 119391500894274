import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rn-design-system'
import styled from 'styled-components'

const LiveIndicator = ({ size, ...props }) => (
  <StyledBox
    {...props}
    width={size}
    height={size}
    alignItems="center"
    justifyContent="center"
    border="1px dashed"
  />
)

LiveIndicator.propTypes = {
  size: PropTypes.number,
}

LiveIndicator.defaultProps = {
  size: 20,
}

const StyledBox = styled(Box)`
  border-radius: 100%;
  background-color: ${({ theme }) => theme.live.backgroundColor};

  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;

  @keyframes blink {
    0% {
      background-color: #ff4136;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: #ff4136;
    }
  }
`

export default LiveIndicator
