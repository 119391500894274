import React from 'react'
import { Text, Alignment } from 'rn-design-system'
import PlaylistLayout from './PlaylistLayout'
import usePlaylistCollection from './usePlaylistCollection'
import PlaylistItemModal from './playlist-item-modal'
import CreatePlaylist from './create-playlist'

const PlaylistSettings = () => {
  const { loading, current } = usePlaylistCollection()

  if (loading) {
    return null
  }

  return (
    <Alignment flexDirection="column" mt={3}>
      <Alignment mb={3}>
        <CreatePlaylist />
      </Alignment>

      {current?.length <= 0 ? (
        <Text>no playlists</Text>
      ) : (
        <PlaylistLayout mt={3}>
          {current.map((playlist) => (
            <PlaylistItemModal
              key={playlist.id}
              title={playlist.name}
              playlist={playlist}
            />
          ))}
        </PlaylistLayout>
      )}
    </Alignment>
  )
}

export default PlaylistSettings
