import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import SocialLinkForm, { LINK_TYPES } from './SocialLinkForm'

const LinkSettings = ({ settings }) => {
  const firebase = useFirebase()

  const setSoundcloudLink = async (values) => {
    if (values?.link?.includes('soundcloud.com')) {
      try {
        await firebase.settings.update({
          'links.soundcloud': values.link,
        })
      } catch (error) {
        await firebase.error.insert(
          `error setting soundcloud url: ${error?.message}`
        )
      }
    }
  }

  const setInstagramLink = async (values) => {
    if (values?.link?.includes('instagram.com')) {
      try {
        await firebase.settings.update({ 'links.instagram': values.link })
      } catch (error) {
        await firebase.error.insert(
          `error setting instagram url: ${error?.message}`
        )
      }
    }
  }

  const setWebsiteLink = async (values) => {
    try {
      await firebase.settings.update({ 'links.website': values.link })
    } catch (error) {
      await firebase.error.insert(
        `error setting instagram url: ${error?.message}`
      )
    }
  }

  const defaultSoundcloudLink = 'https://soundcloud.com/syncersworldwide'
  const defaultInstagramLink = 'https://www.instagram.com/syncers.worldwide/'

  return (
    <Alignment flexDirection="column" width="100%" mt={3}>
      <Text variant={Text.variants.large}>link settings:</Text>
      <SocialLinkForm
        linkType={LINK_TYPES.SOUNDCLOUD}
        linkValue={settings?.links?.soundcloud}
        linkPlaceholder={defaultSoundcloudLink}
        setLink={setSoundcloudLink}
      />
      <SocialLinkForm
        linkType={LINK_TYPES.INSTAGRAM}
        linkValue={settings?.links?.instagram}
        linkPlaceholder={defaultInstagramLink}
        setLink={setInstagramLink}
      />
      <SocialLinkForm
        linkType={LINK_TYPES.WEBSITE}
        linkValue={settings?.links?.website}
        linkPlaceholder="set a website link 👇"
        setLink={setWebsiteLink}
      />
    </Alignment>
  )
}

LinkSettings.propTypes = {
  settings: PropTypes.shape({
    links: PropTypes.shape({
      soundcloud: PropTypes.string,
      instagram: PropTypes.string,
    }),
  }).isRequired,
}

export default LinkSettings
