import { firestore as firebaseFirestore } from 'firebase/app'

class Playlist {
  #collection = 'playlists'

  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  update = async (update) => {
    const document = await this.firestore
      .collection(this.#collection)
      .doc(this.currentRoom)

    return document.update(update)
  }

  addPlayist = ({ name }) => {
    const batch = this.firestore.batch()

    const ref = this.firestore.collection(this.#collection).doc()

    batch.set(ref, {
      name,
      mixes: [],
      dateCreated: firebaseFirestore.Timestamp.now(),
      room: this.currentRoom,
    })

    return batch.commit()
  }

  addToPlaylist = ({ name, url, duration, playlist }) => {
    const batch = this.firestore.batch()

    const queueItemRef = this.firestore.collection('queueItems').doc()
    const playlistRef = this.firestore
      .collection(this.#collection)
      .doc(playlist)

    batch.set(queueItemRef, {
      name,
      url,
      duration,
      startTime: null,
      dateCreated: firebaseFirestore.Timestamp.now(),
    })

    batch.set(
      playlistRef,
      {
        mixes: firebaseFirestore.FieldValue.arrayUnion(queueItemRef),
      },
      { merge: true }
    )

    return batch.commit()
  }

  addPlaylistToQueue = async (playlist) => {
    const batch = this.firestore.batch()

    const queueRef = this.firestore.collection('queues').doc(this.currentRoom)
    const playlistRef = await this.firestore
      .collection(this.#collection)
      .doc(playlist)
      .get()

    if (!playlistRef?.exists) {
      throw new Error('playlist doesnt exist')
    }

    const { mixes = [] } = playlistRef.data()

    batch.set(
      queueRef,
      {
        mixes: firebaseFirestore.FieldValue.arrayUnion(...mixes),
      },
      { merge: true }
    )

    return batch.commit()
  }
}

export default Playlist
