import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Alignment, Input, Button, Text } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'

const Login = () => {
  const firebase = useFirebase()
  const { register, handleSubmit, errors, formState } = useForm()
  const [loginErrors, setLoginErrors] = useState()

  const login = async ({ email, password }) => {
    try {
      await firebase.auth.signIn(email, password)
    } catch (error) {
      await firebase.error.insert(
        `error logging in with email ${email}: ${error?.message}`
      )
      setLoginErrors(error)
    }
  }

  return (
    <Alignment
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      mt={3}
    >
      <form onSubmit={handleSubmit(login)}>
        <Alignment flexDirection="column" minWidth="30vw">
          <Input
            type="email"
            placeholder="email"
            name="email"
            ref={register({
              required: true,
              maxLength: 80,
            })}
            mb={3}
          />
          <Input
            type="password"
            placeholder="password"
            name="password"
            ref={register({ required: true, maxLength: 100 })}
            mb={3}
          />

          <Button disabled={formState?.isSubmitting} type="submit" mb={2}>
            lets go
          </Button>
          {(errors.email || errors.password || loginErrors) && (
            <Text>something went wrong, please try again</Text>
          )}
        </Alignment>
      </form>
    </Alignment>
  )
}

export default Login
