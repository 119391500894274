class Auth {
  constructor(authentication) {
    this.authentication = authentication
  }

  createUser = (email, password) =>
    this.authentication.createUserWithEmailAndPassword(email, password)

  signIn = (email, password) =>
    this.authentication.signInWithEmailAndPassword(email, password)

  signOut = () => this.authentication.signOut()

  resetPassword = email => this.authentication.sendPasswordResetEmail(email)

  updatePassword = password =>
    this.authentication.currentUser.updatePassword(password)
}

export default Auth
