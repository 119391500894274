import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Stream from 'components/shared/stream'
import { Alignment, Box, Button, Text } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import { useRoomContext, useSettingsContext } from 'context/room-context'
import SpacedText from '../SpacedText'
import StreamInput from './StreamInput'

const STREAMS = {
  TWITCH: {
    name: '',
    url: 'https://player.twitch.tv/?channel=syncersworldwide&parent=syncers.club',
  },
  NTS: {
    name: 'NTS',
    url: 'https://stream-relay-geo.ntslive.net/stream?client=NTSWebApp',
  },
  BALAMII: {
    name: 'BALAMII',
    url: 'https://balamii.out.airtime.pro/balamii_a',
  },
}

const StreamAdmin = () => {
  const {
    room: { radio },
  } = useRoomContext()
  const { settings } = useSettingsContext()
  const firebase = useFirebase()
  const [showNameForm, setShowNameForm] = useState(false)
  const [showStreamForm, setShowStreamForm] = useState(false)

  const setRadioName = async (values) => {
    try {
      await firebase.room.setRadioName(values.name)
      setShowNameForm(false)
    } catch (error) {
      await firebase.error.insert(
        `error updating radio name: ${error?.message}`
      )
    }
  }

  const setStreamLink = async (values) => {
    try {
      await firebase.room.setRadioStream(values.stream)
      setShowStreamForm(false)
    } catch (error) {
      await firebase.error.insert(
        `error updating radio stream: ${error?.message}`
      )
    }
  }

  const setRadio = async (values) => {
    try {
      await firebase.room.setRadio(values)
      setShowStreamForm(false)
    } catch (error) {
      await firebase.error.insert(
        `error setting stream to ${JSON.stringify(values)}: ${error?.message}`
      )
    }
  }

  return (
    <Alignment flexDirection="column" width="100%" alignItems="center">
      <Stream url={radio.url} name={radio.name} />
      <Box mt={3}>
        <Button
          onClick={() =>
            firebase.settings.update({
              'switches.streamLive': !settings?.switches?.streamLive,
            })
          }
        >
          {settings?.switches?.streamLive
            ? 'turn stream off'
            : 'turn stream on'}
        </Button>
      </Box>
      <Alignment>
        <SpacedText>
          syncers live
          {radio.name ? ` - ${radio.name}` : ''}
        </SpacedText>
      </Alignment>
      <SpacedText>{radio.url}</SpacedText>
      <Alignment
        flexDirection={{ s: 'column', m: 'row' }}
        justifyContent="space-between"
        width="100%"
      >
        <Alignment justifyContent="space-between">
          <HiddenForm
            type="text"
            name="name"
            placeholder="stream name"
            onSubmit={setRadioName}
            buttonText="change stream name"
            showForm={showNameForm}
            setShowForm={setShowNameForm}
          />
          <HiddenForm
            type="url"
            name="stream"
            placeholder="stream link"
            onSubmit={setStreamLink}
            buttonText="change stream link"
            showForm={showStreamForm}
            setShowForm={setShowStreamForm}
          />
        </Alignment>
        <Alignment mt={3} justifyContent="space-between">
          <Button onClick={() => setRadio(STREAMS.TWITCH)}>Twitch</Button>
          <Button onClick={() => setRadio(STREAMS.NTS)}>NTS</Button>
          <Button onClick={() => setRadio(STREAMS.BALAMII)}>Balamii</Button>
        </Alignment>
      </Alignment>
      <StreamLinks />
    </Alignment>
  )
}

const StreamLinks = () => (
  <Alignment flexDirection="column" mt={3}>
    <Text variant={Text.variants.large}>stream embed examples:</Text>
    <Text>twitch: https://player.twitch.tv/?channel=CHANNEL_NAME</Text>
    <Text>mixer: https://mixer.com/embed/player/CHANNEL_NAME</Text>
  </Alignment>
)

const HiddenForm = ({
  type,
  name,
  placeholder,
  onSubmit,
  buttonText,
  showForm,
  setShowForm,
}) => {
  return (
    <Alignment flexDirection="column">
      {showForm && (
        <Box mt={3}>
          <StreamInput
            type={type}
            name={name}
            placeholder={placeholder}
            onSubmit={onSubmit}
          />
        </Box>
      )}
      <Box mt={3}>
        <Button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'hide' : buttonText}
        </Button>
      </Box>
    </Alignment>
  )
}

HiddenForm.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  showForm: PropTypes.bool.isRequired,
  setShowForm: PropTypes.func.isRequired,
}

export default StreamAdmin
