class Settings {
  #defaultSettings = {
    curated: false,
    description: '',
    footer: '',
    switches: {
      showChat: true,
      historyTicker: true,
      streamLive: false,
      queueLive: false,
      historyClickable: true,
    },
    links: {
      instagram: 'https://www.instagram.com/syncers.worldwide',
      soundcloud: 'https://soundcloud.com/syncersworldwide',
      website: '',
    },
    visuals: {
      logo:
        'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/design%2Flogo-white-transparent.png?alt=media',
      background: '',
      cursor:
        'https://firebasestorage.googleapis.com/v0/b/syncers-2f844.appspot.com/o/images%2Fglobe.png?alt=media',
      randomize: false,
    },
    pageStyle: {
      font: '',
      headerFont: '',
      fontAdjust: null,
      textShadow: '',
      backgroundOpacity: 0.7,
    },
    layout: {
      topRight: 'FEATURED',
    },
  }

  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  update = async (update) => {
    const document = await this.firestore
      .collection('settings')
      .doc(this.currentRoom)

    return document.update(update)
  }

  init = async (roomName) => {
    const batch = this.firestore.batch()

    const document = await this.firestore.collection('settings').doc(roomName)

    batch.set(document, this.#defaultSettings, { merge: true })

    const room = await this.firestore.collection('rooms').doc(roomName)

    batch.set(room, { settings: document }, { merge: true })

    return batch.commit()
  }

  delete = async (roomName) => {
    await this.firestore.collection('settings').doc(roomName).delete()
  }
}
export default Settings
