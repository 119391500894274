import React, { createContext, useReducer, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'

const MixContext = createContext()

const actions = {
  SET_HAS_FINISHED: 'SET_HAS_FINISHED',
  SET_IS_PLAYING: 'SET_IS_PLAYING',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_HAS_FINISHED:
      return { ...state, hasFinished: action.payload }
    case actions.SET_IS_PLAYING:
      return { ...state, isPlaying: action.payload }
    default:
      return state
  }
}

const MixProvider = ({ children }) => {
  const initialState = {
    hasFinished: false,
    isPlaying: false,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <MixContext.Provider value={contextValue}>{children}</MixContext.Provider>
  )
}

MixProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useMixContext = () => useContext(MixContext)

export { MixProvider, useMixContext, actions }
