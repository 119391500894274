class Admins {
  #defaultSettings = {
    curators: [],
    managers: [],
  }

  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  #updateSettings = async (update) => {
    const batch = this.firestore.batch()

    const document = await this.firestore
      .collection('admins')
      .doc(this.currentRoom)

    batch.set(document, update, { merge: true })

    return batch
  }

  update = async (update) => {
    const batch = await this.#updateSettings(update)

    return batch.commit()
  }

  init = async (roomName) => {
    const batch = this.firestore.batch()

    const document = await this.firestore.collection('admins').doc(roomName)

    batch.set(document, this.#defaultSettings, { merge: true })

    const room = await this.firestore.collection('rooms').doc(roomName)

    batch.set(room, { admins: document }, { merge: true })

    return batch.commit()
  }

  delete = async (roomName) => {
    await this.firestore.collection('admins').doc(roomName).delete()
  }
}

export default Admins
