import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rn-design-system'

const Layout = ({ children, ...props }) => (
  <Box
    display="grid"
    gridTemplateColumns={{
      s: 'repeat(2, 1fr)',
      m: 'repeat(3, 1fr)',
      l: 'repeat(4, 1fr)',
    }}
    gridGap={3}
    width="100%"
    height="100%"
    mb={3}
    {...props}
  >
    {children}
  </Box>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
