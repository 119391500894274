import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Box } from 'rn-design-system'
import ReactPlayer from 'react-player'
import Loading from 'components/shared/loading'
import AddMixForm from './AddMixForm'

const ANALYZE_STATE = {
  IDLE: 'IDLE',
  ANALYZING: 'ANALYZING',
}

const AddMix = ({ onAnalyzed, addButtonText }) => {
  const [mix, setMix] = useState({
    name: '',
    url: '',
  })
  const [analyzing, setAnalyzing] = useState(ANALYZE_STATE.IDLE)

  const add = async (duration) => {
    await onAnalyzed({
      name: mix.name,
      url: mix.url,
      duration,
    })

    setMix({
      name: '',
      url: '',
    })

    setAnalyzing(ANALYZE_STATE.IDLE)
  }

  return (
    <>
      <Add
        analyzing={analyzing}
        setAnalyzing={setAnalyzing}
        setMix={setMix}
        addButtonText={addButtonText}
      />
      {analyzing === ANALYZE_STATE.ANALYZING && mix?.url && (
        <Analyzer url={mix?.url} onDuration={add} />
      )}
    </>
  )
}

AddMix.propTypes = {
  onAnalyzed: PropTypes.func.isRequired,
  addButtonText: PropTypes.string,
}

AddMix.defaultProps = {
  addButtonText: 'add',
}

const Add = ({ analyzing, setAnalyzing, setMix, addButtonText }) => {
  const [showForm, setShowForm] = useState(false)

  const add = async ({ url, name }) => {
    setAnalyzing(ANALYZE_STATE.ANALYZING)
    setMix({ url, name })
  }

  return showForm ? (
    <Box width="100%">
      <AddMixForm
        onAdded={add}
        setShowForm={setShowForm}
        addButtonText={addButtonText}
      />
    </Box>
  ) : (
    <Box>
      <Button
        disabled={analyzing === ANALYZE_STATE.ANALYZING}
        onClick={() => setShowForm(true)}
      >
        {analyzing === ANALYZE_STATE.ANALYZING ? (
          <Loading loading />
        ) : (
          addButtonText
        )}
      </Button>
    </Box>
  )
}

Add.propTypes = {
  analyzing: PropTypes.oneOf(Object.values(ANALYZE_STATE)).isRequired,
  setAnalyzing: PropTypes.func.isRequired,
  setMix: PropTypes.func.isRequired,
  addButtonText: PropTypes.string,
}

Add.defaultProps = {
  addButtonText: 'add',
}

const Analyzer = ({ url, onDuration }) => {
  const ref = useRef()

  const setTime = async () => {
    if (ref && ref.current) {
      const duration = ref.current.getDuration()

      onDuration(duration)
    }
  }

  return (
    <ReactPlayer
      ref={ref}
      url={url}
      playing
      muted
      volume={0}
      onReady={() => setTime()}
      width="0x"
      height="0px"
      config={{
        options: {
          single_active: false,
        },
      }}
    />
  )
}

Analyzer.propTypes = {
  url: PropTypes.string.isRequired,
  onDuration: PropTypes.func.isRequired,
}

export default AddMix
