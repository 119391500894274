import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'rn-design-system'

const Decision = ({ canDecide, poll, decide, ...props }) => {
  if (!canDecide) {
    return null
  }

  return (
    <Box {...props}>
      <Button variant="visuals" onClick={() => decide()}>
        its decision time, press me
      </Button>
    </Box>
  )
}

Decision.propTypes = {
  canDecide: PropTypes.bool.isRequired,
  poll: PropTypes.arrayOf(PropTypes.object).isRequired,
  decide: PropTypes.func.isRequired,
}

export default Decision
