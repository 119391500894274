import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Grid } from 'rn-design-system'
import { useRoomContext, useSettingsContext } from 'context/room-context'
import { SystemAdminAuthenticatedComponent } from 'context/user-context'
import Empty from 'components/shared/empty'
import AdminLayout, { Areas } from './AdminLayout'
import MixAdmin from './components/mix-admin'
import RadioAdmin from './components/stream-admin'
import Nav from './components/nav'

const TABS = {
  MIX: 'mix',
  RADIO: 'radio',
}

const getTab = (tab) => (Object.values(TABS).includes(tab) ? tab : 'mix')

const RoomAdmin = () => {
  const { tab } = useParams()

  return (
    <SystemAdminAuthenticatedComponent>
      <RoomAdminContent tab={getTab(tab)} />
    </SystemAdminAuthenticatedComponent>
  )
}

const RoomAdminContent = ({ tab }) => {
  const { loading, room } = useRoomContext()
  const { loading: settingsLoading, settings } = useSettingsContext()

  if (loading || settingsLoading) {
    return null
  }

  return (
    <>
      <AdminLayout>
        <Grid.Area area={Areas.Nav}>
          <Nav
            selectedTab={tab}
            currentRoom={room.name}
            radioLive={settings?.switches?.streamLive}
            showNav={room?.exists}
          />
        </Grid.Area>
        <Grid.Area area={Areas.Content}>
          {room?.exists ? (
            <Tabs tab={tab} />
          ) : (
            <Empty>room isnt active or doesnt exist</Empty>
          )}
        </Grid.Area>
      </AdminLayout>
    </>
  )
}

RoomAdminContent.propTypes = {
  tab: PropTypes.string.isRequired,
}

const Tabs = ({ tab }) => (
  <>
    {tab === TABS.MIX && <MixAdmin />}
    {tab === TABS.RADIO && <RadioAdmin />}
  </>
)

Tabs.propTypes = {
  tab: PropTypes.string.isRequired,
}

export default RoomAdmin
