import React from 'react'
import { useFirebase } from 'firebase-utils'
import { Button } from 'rn-design-system'
import { useSettingsContext } from 'context/room-context'

const ChatSettings = () => {
  const firebase = useFirebase()
  const { settings } = useSettingsContext()

  const triggerRdm = async (value) => {
    try {
      await firebase.settings.update({ 'visuals.randomize': value })
    } catch (error) {
      await firebase.error.insert(`error setting randomize: ${error?.message}`)
    }
  }

  return (
    <Button
      variant="visuals"
      onClick={() => triggerRdm(!settings?.visuals?.randomize)}
    >
      {settings?.visuals?.randomize ? 'not rdm' : 'rdm'}
    </Button>
  )
}

export default ChatSettings
