import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box } from 'rn-design-system'
import { useRoomContext } from 'context/room-context'
import HistoryList from 'components/shared/history/history-list'
import ToggleHistoryTicker from './toggle-history-ticker'
import ToggleHistoryClickable from './toggle-history-clickable'

const HistorySettings = ({ settings }) => {
  const { room, loading } = useRoomContext()

  if (loading) {
    return null
  }

  return (
    <Alignment flexDirection="column" width="100%" mt={3}>
      <ToggleHistoryClickable
        historyClickable={settings?.switches?.historyClickable}
      />
      <Box mt={3}>
        <ToggleHistoryTicker
          historyTicker={settings?.switches?.historyTicker}
        />
      </Box>
      <Box style={{ maxWidth: 350 }} mt={3}>
        <HistoryList
          headerText="last played"
          limit={10}
          currentRoom={room?.name}
        />
      </Box>
    </Alignment>
  )
}

HistorySettings.propTypes = {
  settings: PropTypes.shape({
    switches: PropTypes.shape({
      historyTicker: PropTypes.bool,
    }),
  }).isRequired,
}

export default HistorySettings
