import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import { useRoomContext } from 'context/room-context'
import HiddenForm from 'components/generic/hidden-form'
import { useFirebase } from 'firebase-utils'
import EditableInput from 'components/generic/editable-input'
import ToggleStream from './toggle-stream'

const StreamSettings = ({ settings }) => {
  const firebase = useFirebase()
  const { room, loading } = useRoomContext()

  const setStreamLink = async (values) => {
    try {
      await firebase.room.setRadioStream(values.stream)
    } catch (error) {
      await firebase.error.insert(
        `error updating radio stream: ${error?.message}`
      )
    }
  }

  if (loading) {
    return null
  }

  return (
    <Alignment flexDirection="column" width="100%" mt={3}>
      <ToggleStream streamLive={settings?.switches?.streamLive} />
      <Alignment mt={3}>
        {room?.radio?.url ? (
          <Text>{room?.radio?.url}</Text>
        ) : (
          <Text color="tertiary">add a stream url below</Text>
        )}
        <Box mx={3}>
          <Text>/</Text>
        </Box>
        <EditableInput
          value={room?.radio?.name}
          onComplete={firebase.room.setRadioName}
          placeholder="stream name"
        />
      </Alignment>
      <Alignment flexDirection={{ s: 'column', m: 'row' }} mt={2}>
        <HiddenForm
          type="url"
          name="stream"
          placeholder="stream link"
          onSubmit={setStreamLink}
          buttonText="change stream link"
          horizontal
        />
      </Alignment>
      <StreamLinks />
    </Alignment>
  )
}

StreamSettings.propTypes = {
  settings: PropTypes.shape({
    switches: PropTypes.shape({
      showChat: PropTypes.bool,
    }),
  }).isRequired,
}

const StreamLinks = () => (
  <Alignment flexDirection="column" mt={3}>
    <Text variant={Text.variants.large}>stream embed examples:</Text>
    <Box mt={2}>
      <Text>twitch: https://player.twitch.tv/?channel=CHANNEL_NAME&parent=syncers.club</Text>
    </Box>
    <Box mt={2}>
      <Text>mixer: https://mixer.com/embed/player/CHANNEL_NAME</Text>
    </Box>
  </Alignment>
)

export default StreamSettings
