import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import LogoutButton from 'components/shared/logout-button'
import AuthenticatedComponent from './AuthenticatedComponent'
import { useUserContext } from './UserContext'

const SystemAdminAuthenticatedComponent = ({ children }) => (
  <AuthenticatedComponent>
    <SystemAdmins>{children}</SystemAdmins>
  </AuthenticatedComponent>
)

SystemAdminAuthenticatedComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

const SystemAdmins = ({ children }) => {
  const { loading, isAdmin } = useUserContext()

  if (loading) {
    return null
  }

  if (!isAdmin) {
    return <Unauthorized />
  }

  return <>{children}</>
}

SystemAdmins.propTypes = {
  children: PropTypes.node.isRequired,
}

const Unauthorized = () => {
  return (
    <Alignment flexDirection="column" alignItems="center" p={6} width="100%">
      <Text variant={Text.variants.xLarge}>sorry not allowed</Text>
      <Box mt={3}>
        <LogoutButton />
      </Box>
    </Alignment>
  )
}

export default SystemAdminAuthenticatedComponent
