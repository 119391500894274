import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'

const YoutubeEmbed = ({ url }) => {
  if (!url || !url.includes('youtube')) {
    return null
  }

  return (
    <ReactPlayer
      url={url}
      playing
      muted
      volume={0}
      width="100%"
      height="250px"
      config={{
        options: {
          single_active: false,
        },
      }}
    />
  )
}

YoutubeEmbed.propTypes = {
  url: PropTypes.string,
}

YoutubeEmbed.defaultProps = {
  url: null,
}

export default YoutubeEmbed
