import React from 'react'
import { Alignment, Box, Text } from 'rn-design-system'
import LogoutButton from 'components/shared/logout-button'

const Heading = () => (
  <Box mx={{ s: 0, m: 6 }}>
    <Alignment
      flexDirection={{ s: 'column', m: 'row' }}
      justifyContent="space-between"
    >
      <Text variant={Text.variants.xLarge}>syncers worldwide admin</Text>
      <LogoutButton />
    </Alignment>
  </Box>
)

export default Heading
