import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const ROLES = {
  ADMIN: 'ADMIN',
}

const useIsAdmin = (user, userLoading) => {
  const [isAdminResult, setIsAdmin] = useState({ loading: true })

  useEffect(() => {
    const getToken = async () => {
      try {
        if (user) {
          const tokenResult = await user.getIdTokenResult()

          const { role } = tokenResult?.claims

          setIsAdmin({ loading: false, isAdmin: role === ROLES.ADMIN })
        } else {
          setIsAdmin({ loading: false, isAdmin: false })
        }
      } catch (tokenError) {
        toast.error(tokenError.message)
        setIsAdmin({ loading: false, isAdmin: false })
      }
    }

    if (!userLoading) {
      getToken()
    }
  }, [user, userLoading])

  return isAdminResult
}

export default useIsAdmin
