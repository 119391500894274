import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Input, Text, Button } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import { useSyncerContext } from 'context/syncer-context'
import { useRoomAdminContext } from 'context/room-admin-context'

const ChatInput = ({ name }) => {
  const syncer = useSyncerContext()
  const { authorized } = useRoomAdminContext()
  const firebase = useFirebase()
  const [message, setMessage] = useState('')
  const onChange = (e) => {
    setMessage(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (message && message.length > 0) {
      try {
        const text = message
        setMessage('')
        await firebase.chat.sendMessage({
          message: text,
          syncer,
          name,
          curator: authorized,
        })
      } catch (error) {
        await firebase.error.insert(`error sending message: ${error?.message}`)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alignment alignItems="flex-end" width="100%">
        <Alignment
          borderLeft="1px dashed"
          borderTop="1px dashed"
          height="40px"
          flexDirection="column"
          justifyContent="centre"
          width="100%"
        >
          <Input
            type="message"
            placeholder="send message"
            name="message"
            onChange={onChange}
            value={message}
            textVariant={Text.variants.medium}
            p={2}
            autoComplete="off"
          />
        </Alignment>
        <Button
          disabled={!syncer?.id || !syncer?.name}
          textVariant={Text.variants.large}
          type="submit"
          underline
        >
          &gt;
        </Button>
      </Alignment>
    </form>
  )
}

ChatInput.propTypes = {
  name: PropTypes.string,
}

ChatInput.defaultProps = {
  name: '',
}

export default ChatInput
