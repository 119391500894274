import React from 'react'
import { Alignment } from 'rn-design-system'
import { useSyncerContext } from 'context/syncer-context'
import styled from 'styled-components'
import { useRoomAdminContext } from 'context/room-admin-context'
import Message from './Message'
import useChatCollection from './useChatCollection'
import ChatSettings from './ChatSettings'

const Messages = () => {
  const syncer = useSyncerContext()
  const currentChat = useChatCollection()
  const { isManager } = useRoomAdminContext()

  return (
    <Alignment
      flexDirection="column-reverse"
      border="1px dashed"
      borderBottom="none"
      justifyContent="flex-start"
      overflow="hidden"
      height="480px"
    >
      {isManager && (
        <Overlay justifyContent="flex-end">
          <ChatSettings />
        </Overlay>
      )}
      {currentChat.map((message) => (
        <Message key={message.id} message={message} syncer={syncer} />
      ))}
    </Alignment>
  )
}

const Overlay = styled(Alignment)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export default Messages
