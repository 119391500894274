import { useState, useEffect } from 'react'
import hasDocs from 'helpers/hasDocs'
import { useFirebase } from 'firebase-utils'
import { useCollection } from 'react-firebase-hooks/firestore'

const usePlaylistCollection = () => {
  const { firestore, currentRoom } = useFirebase()
  const [current, setCurrent] = useState([])
  const [collection, loading] = useCollection(
    firestore
      .collection('playlists')
      .where('room', '==', currentRoom)
      .orderBy('dateCreated', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (!loading && hasDocs(collection)) {
      const result = collection.docs.reduce((a, b) => {
        const data = b.data()

        let { dateCreated } = data

        if (dateCreated?.toDate) {
          dateCreated = dateCreated.toDate()
        }

        return [...a, { id: b.id, ...data, dateCreated }]
      }, [])

      setCurrent(result)
    }
  }, [collection, loading])

  return { current, loading }
}

export default usePlaylistCollection
