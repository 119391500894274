import { createGlobalStyle } from 'styled-components'
import ClubQu from 'static/fonts/ABCViafont-Regular2.otf'
import Technomate from 'static/fonts/FoundersGrotesk-Regular.otf'
import MagneticFields from 'static/fonts/MagneticFields-Thin.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'ABCViafont';
        src: url(${ClubQu}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Technomate';
        src: url(${Technomate}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'MagneticFields';
        src: url(${MagneticFields}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`
