import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/generic/modal'
import { getRoomName } from 'helpers/getRoom'
import { AuthenticatedComponent } from 'context/user-context'
import Settings from './Settings'

const SettingsModal = ({ roomName }) => (
  <Modal buttonText="&#10011;" title={getRoomName(roomName)}>
    <AuthenticatedComponent>
      <Settings />
    </AuthenticatedComponent>
  </Modal>
)

SettingsModal.propTypes = {
  roomName: PropTypes.string.isRequired,
}

export default SettingsModal
