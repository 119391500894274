export default {
  apiKey: 'AIzaSyAo_-G0mqenDjYhwEYatyLkhekq9dY1zZk',
  authDomain: 'syncers-dev.firebaseapp.com',
  databaseURL: 'https://syncers-dev.firebaseio.com',
  projectId: 'syncers-dev',
  storageBucket: 'syncers-dev.appspot.com',
  messagingSenderId: '519847200681',
  appId: '1:519847200681:web:b241668d599ad784eda6b8',
  measurementId: 'G-N8SVEDWBHQ',
}
