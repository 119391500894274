class FeatureSwitches {
  constructor() {
    this.enableDeleting = false
    this.partnerLive = true
    this.supportedPlatforms = ['soundcloud', 'youtube', 'mixcloud']
    this.supportedQueuePlatforms = ['soundcloud', 'youtube']
    // this.roomTakeover = 'magnetic-fields-festival'
    this.roomTakeover = null
  }
}

export default FeatureSwitches
