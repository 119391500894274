import delay from 'delay'
import { set } from 'lodash'

const DEFAULT_GIF = 'https://media.giphy.com/media/XfJx8XeRptQsv4L0K4/giphy.gif'

class Reaction {
  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom

    this.REACTION_TYPES = {
      BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
    }
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  #updateReaction = async ({ id = undefined, update }) => {
    const reactionBatch = this.firestore.batch()

    let reaction

    if (id) {
      reaction = await this.firestore.collection('reactions').doc(id)
    } else {
      reaction = await this.firestore.collection('reactions').doc()
    }

    reactionBatch.set(reaction, update, { merge: true })

    return reactionBatch
  }

  create = async (field, value) => {
    const update = {
      live: false,
      action: {
        type: this.REACTION_TYPES.BACKGROUND_IMAGE,
        url: DEFAULT_GIF,
      },
      emoji: '🚨',
      lastUsed: 0,
      active: false,
      room: this.currentRoom,
      createdDate: Date.now(),
    }

    if (value && field) {
      set(update, field, value)
    }

    const batch = await this.#updateReaction({ update })

    return batch.commit()
  }

  update = async (id, update) => {
    const document = await this.firestore.collection('reactions').doc(id)

    return document.update(update)
  }

  toggleActive = async (id, active) => {
    const batch = await this.#updateReaction({
      id,
      update: {
        active,
        lastUsed: Date.now(),
      },
    })

    return batch.commit()
  }

  activate = (reactionId) => async () => {
    await this.toggleActive(reactionId, true)

    await delay(3000)

    await this.toggleActive(reactionId, false)
  }

  init = async (roomName) => {
    const update = {
      live: true,
      action: {
        type: this.REACTION_TYPES.BACKGROUND_IMAGE,
        url: DEFAULT_GIF,
      },
      emoji: '🚨',
      lastUsed: 0,
      active: false,
      room: roomName,
      createdDate: Date.now(),
    }

    const batch = await this.#updateReaction({ update })

    return batch.commit()
  }

  delete = async (roomName) => {
    const reactions = this.firestore
      .collection('reactions')
      .where('room', '==', roomName)

    return reactions.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete()
      })
    })
  }
}

export default Reaction
