import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import { useRoomAdminContext } from 'context/room-admin-context'
import { useSettingsContext } from 'context/room-context'
import LogoutButton from 'components/shared/logout-button'
import RoomSettings from './room-settings'

const Settings = () => {
  const { settings, loading } = useSettingsContext()
  const {
    authorized,
    isManager,
    loading: roomAdminLoading,
  } = useRoomAdminContext()

  if (loading || roomAdminLoading) {
    return null
  }

  if (!authorized) {
    return <Nope>sorry not allowed here</Nope>
  }

  if (!settings?.exists) {
    return <Nope>nothing here sorry</Nope>
  }

  return <SettingsContent isManager={isManager} />
}

const SettingsContent = ({ isManager }) => (
  <Alignment
    flexDirection="column"
    height="100%"
    justifyContent="space-between"
  >
    {isManager ? <RoomSettings /> : <GetSomeMixesOn />}
    <Alignment justifyContent="flex-end">
      <LogoutButton />
    </Alignment>
  </Alignment>
)

SettingsContent.propTypes = {
  isManager: PropTypes.bool.isRequired,
}

const GetSomeMixesOn = () => (
  <Alignment flexDirection="column" alignItems="center" p={6} width="100%">
    <Text variant={Text.variants.xLarge}>get some mixes on</Text>
  </Alignment>
)

const Nope = ({ children }) => (
  <Alignment flexDirection="column" alignItems="center" p={6} width="100%">
    <Text variant={Text.variants.xLarge}>{children}</Text>
    <Box mt={3}>
      <LogoutButton />
    </Box>
  </Alignment>
)

Nope.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Settings
