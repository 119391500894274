import React from 'react'
import PropTypes from 'prop-types'
import TOP_RIGHT from 'enums/layout/top-right'
import { Alignment, Box, Text } from 'rn-design-system'
import DoubleCheckButton from 'components/generic/double-check-button'
import { useFirebase } from 'firebase-utils'

const TopRight = ({ topRight }) => {
  const firebase = useFirebase()

  const setTopRight = async (topRightValue) => {
    try {
      await firebase.settings.update({
        'layout.topRight': topRightValue,
      })
    } catch (error) {
      await firebase.error.insert(
        `error setting soundcloud url: ${error?.message}`
      )
    }
  }

  return (
    <Alignment flexDirection="column">
      <Box mb={2}>
        <Text>set top right section</Text>
      </Box>
      {Object.values(TOP_RIGHT).map((value) => (
        <Box key={value} mb={2}>
          <TopRightValue
            value={value}
            select={setTopRight}
            selected={value === topRight}
          />
        </Box>
      ))}
      <Box>
        <DoubleCheckButton
          onSubmit={() => setTopRight(null)}
          buttonText="hide"
        />
      </Box>
    </Alignment>
  )
}

TopRight.propTypes = {
  topRight: PropTypes.string.isRequired,
}

const TopRightValue = ({ value, select, selected }) => (
  <DoubleCheckButton
    onSubmit={() => select(value)}
    buttonText={selected ? `${value} (current)` : value}
  />
)

TopRightValue.propTypes = {
  value: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default TopRight
