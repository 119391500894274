import React from 'react'
import { Alignment, Box, Text } from 'rn-design-system'
import Rooms from './Rooms'
import CreateRoom from './CreateRoom'

const RoomsList = () => (
  <Alignment flexDirection="column" height="100%">
    <Text variant={Text.variants.large}>all rooms</Text>
    <Box mt={3} mb={5}>
      <Rooms usePagination limit={10} />
    </Box>
    <CreateRoom />
  </Alignment>
)

export default RoomsList
