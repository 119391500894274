import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rn-design-system'
import DoubleCheckButton from 'components/generic/double-check-button'
import Loading from 'components/shared/loading'
import useRequest from 'firebase-utils/server-access/useRequest'

const SendPasswordEmail = ({ email }) => {
  const [loading, setLoading] = useState(false)
  const sendPasswordResetEmail = useRequest('/users/send-password-email')

  const onSubmit = async () => {
    setLoading(true)
    await sendPasswordResetEmail({ email })
    setLoading(false)
  }

  return !loading ? (
    <Box ml={2}>
      <DoubleCheckButton buttonText="send pw email" onSubmit={onSubmit} />
    </Box>
  ) : (
    <Loading m={2} loading={loading} />
  )
}

SendPasswordEmail.propTypes = {
  email: PropTypes.string.isRequired,
}

export default SendPasswordEmail
