import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Alignment, Button } from 'rn-design-system'
import { isEmpty } from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Image from 'components/generic/image'
import styled from 'styled-components'
import { useFirebase } from 'firebase-utils'
import DoubleCheckButton from 'components/generic/double-check-button'

const Layout = ({ children }) => (
  <Box
    display="grid"
    gridTemplateColumns={{
      s: 'repeat(2, 1fr)',
      m: 'repeat(3, 1fr)',
      l: 'repeat(4, 1fr)',
    }}
    gridGap={3}
    width="100%"
    height="100%"
    mb={3}
  >
    {children}
  </Box>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const FilesGrid = ({ refetch, files, loading, error }) => {
  const copied = () => toast('url copied')
  const { storage, error: firebaseError } = useFirebase()

  const deleteFile = async (url) => {
    try {
      await storage.deleteBlobOrFile(url)
      if (refetch && typeof refetch === 'function') {
        refetch()
      }
    } catch (err) {
      await firebaseError.insert(`Error deleting file: ${err?.message}`)
    }
  }

  if (loading) {
    return null
  }

  if (error) {
    return <Text>something went wrong getting images</Text>
  }

  if (isEmpty(files)) {
    return <Text>nothing has been uploaded</Text>
  }

  return (
    <Box bg="accent" p={2}>
      <Layout>
        {files.map((url, index) => (
          <Alignment key={index} flexDirection="column">
            <Alignment border="1px dashed" justifyContent="center">
              <CopyToClipboard key={index} text={url} onCopy={copied}>
                <StyledButton type="button" px={0} onClick={() => ({})}>
                  <Image src={url} />
                </StyledButton>
              </CopyToClipboard>
            </Alignment>
            <Alignment mt={2}>
              <CopyToClipboard key={index} text={url} onCopy={copied}>
                <Button onClick={() => ({})}>copy url</Button>
              </CopyToClipboard>
              <Box ml={1}>
                <DoubleCheckButton
                  buttonText="delete"
                  onSubmit={() => deleteFile(url)}
                />
              </Box>
            </Alignment>
          </Alignment>
        ))}
      </Layout>
    </Box>
  )
}

FilesGrid.propTypes = {
  files: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  error: PropTypes.string,
  refetch: PropTypes.func.isRequired,
}

FilesGrid.defaultProps = {
  files: [],
  loading: false,
  error: '',
}

const StyledButton = styled.button`
  border: 1px dashed;
  background: transparent;
  cursor: pointer;
`

export default FilesGrid
