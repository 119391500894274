import React, { useState } from 'react'
import { Alignment, Box, Text, Button } from 'rn-design-system'
import useReactionsCollection from 'hooks/useReactionsCollection'
import EditableInput from 'components/generic/editable-input/EditableInput'
import { useFirebase } from 'firebase-utils'
import { range } from 'lodash'
import ToggleReaction from './ToggleReaction'
import GiphyReactions from './GiphyReactions'

const REACTION_COUNT = 8

const Reactions = () => {
  const [search, setSearch] = useState(false)
  return (
    <Alignment flexDirection="column">
      <ReactionsList />
      <Box mt={3}>
        <Box mb={search ? 2 : 0}>
          <Button onClick={() => setSearch(!search)}>
            {search ? 'hide' : 'search gifs'}
          </Button>
        </Box>
        {search && <GiphyReactions />}
      </Box>
    </Alignment>
  )
}

const ReactionsList = () => {
  const { reaction, error } = useFirebase()
  const { loading, reactions } = useReactionsCollection([true, false])

  const createReaction = async (field, value) => {
    if (value) {
      try {
        await reaction.create(field, value)
      } catch (err) {
        await error.insert(`error creating reaction: ${err?.message}`)
      }
    }
  }

  const updateReaction = async (id, field, value) => {
    if (value) {
      try {
        const update = {}
        update[field] = value
        await reaction.update(id, update)
      } catch (err) {
        await error.insert(
          `error updating reaction ${id}, ${field}: ${err?.message}`
        )
      }
    }
  }

  if (loading) {
    return null
  }

  return (
    <Alignment flexDirection="column" justifyContent="flex-start">
      {reactions.map((react) => (
        <Alignment mt={3} key={react.id}>
          <ToggleReaction id={react.id} live={react.live} />
          <Box mx={3}>
            <Text>/</Text>
          </Box>
          <EditableInput
            value={react.emoji}
            onComplete={(value) => updateReaction(react.id, 'emoji', value)}
            placeholder="emoji"
            required
          />
          <Box mx={3}>
            <Text>/</Text>
          </Box>
          <EditableInput
            value={react?.action?.url}
            onComplete={(value) =>
              updateReaction(react.id, 'action.url', value)
            }
            placeholder="gif url"
            required
          />
        </Alignment>
      ))}
      {range(0, REACTION_COUNT - reactions?.length).map(() => (
        <Alignment mt={3} key={Math.random()} alignItems="center">
          <EditableInput
            value=""
            onComplete={(value) => createReaction('emoji', value)}
            placeholder="emoji"
            required
          />
          <Box mx={3}>
            <Text>/</Text>
          </Box>
          <EditableInput
            value=""
            onComplete={(value) => createReaction('action.url', value)}
            placeholder="gif url"
            required
          />
        </Alignment>
      ))}
    </Alignment>
  )
}

export default Reactions
