import { useFirebase } from 'firebase-utils'
import { useState, useEffect } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

const useQueue = () => {
  const [queue, setQueue] = useState([])
  const firebase = useFirebase()

  const [document, loading] = useDocument(
    firebase.firestore.doc(`queues/${firebase.currentRoom}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    const setQueuedMixes = async (mixes) => {
      const queuedMixes = await mixes.reduce(async (a, mix) => {
        const mixData = await mix.get()
        const acc = await a

        return [...acc, { id: mixData.id, ...mixData.data() }]
      }, [])

      setQueue(queuedMixes)
    }

    if (!loading) {
      if (document?.exists) {
        const result = document.data()

        const { mixes } = result

        setQueuedMixes(mixes)
      }
    }
  }, [document, setQueue, loading])

  return { loading, queue }
}

export default useQueue
