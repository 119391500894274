import React, { useState } from 'react'
import { Alignment, Input, Button, Text, Box } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'

const CreateRoom = () => {
  const roomNameLimit = process.env.REACT_APP_ROOM_NAME_LIMIT
  const firebase = useFirebase()
  const [roomName, setRoomName] = useState('')
  const [showForm, setShowForm] = useState(false)
  const onChange = (e) => {
    setRoomName(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (roomName && roomName.length <= roomNameLimit) {
      setShowForm(false)

      await firebase.init(roomName)

      setRoomName('')
    }
  }

  return (
    <Alignment alignSelf="flex-start" flexDirection="column">
      {showForm && (
        <form onSubmit={handleSubmit}>
          <Alignment alignItems="flex-end" width="100%">
            <Input
              type="name"
              placeholder="room name"
              name="name"
              onChange={onChange}
              value={roomName}
              textVariant={Text.variants.medium}
              p={1}
              autoComplete="off"
              maxLength={roomNameLimit}
            />
            <Button textVariant={Text.variants.large} type="submit">
              create
            </Button>
          </Alignment>
        </form>
      )}
      <Box mt={2}>
        <Button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'nah' : 'create room'}
        </Button>
      </Box>
    </Alignment>
  )
}

export default CreateRoom
