import React from 'react'
import PropTypes from 'prop-types'
import { Alignment } from 'rn-design-system'
import { useRoomContext, useSettingsContext } from 'context/room-context'
import Stream from 'components/shared/stream'
import Empty from 'components/shared/empty'
import Mix from 'components/shared/mix'

const Player = () => {
  const { loading, room } = useRoomContext()
  const { loading: settingsLoading, settings } = useSettingsContext()

  return (
    <Alignment
      maxWidth="100vw"
      width="100%"
      flexDirection="column"
      alignItems="center"
    >
      <NowPlaying
        room={room}
        settings={settings}
        loading={loading || settingsLoading}
      />
    </Alignment>
  )
}

const NowPlaying = ({ loading, room, settings }) => {
  if (loading) {
    return <Empty>loading...</Empty>
  }

  if (settings?.switches?.streamLive) {
    return <Stream url={room.radio.url} name={room?.radio?.name} />
  }

  return <Mix mix={room.mix} />
}

NowPlaying.propTypes = {
  loading: PropTypes.bool,
  room: PropTypes.shape({
    exists: PropTypes.bool,
    mix: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      startTime: PropTypes.number,
    }),
    radio: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  settings: PropTypes.shape({
    switches: PropTypes.shape({
      streamLive: PropTypes.bool,
    }),
  }).isRequired,
}

NowPlaying.defaultProps = {
  loading: false,
}

export default Player
