import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Input, Button, Text, Box } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import { useSyncerContext } from 'context/syncer-context'

const ChangeName = ({ setName }) => {
  const usernameCharacterLimit = 20
  const syncer = useSyncerContext()
  const firebase = useFirebase()
  const [name, setSyncerName] = useState(syncer.name)
  const [showForm, setShowForm] = useState(false)
  const onChange = (e) => {
    setSyncerName(e.target.value.toLowerCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (name && name.length <= usernameCharacterLimit) {
      firebase.syncer.updateSyncerName(name, syncer)
      setName(name)

      setShowForm(false)
    }
  }

  return (
    <Box mt={3}>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <Alignment alignItems="flex-end" width="100%">
            <Alignment
              height="40px"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
            >
              <Input
                type="name"
                placeholder="change name"
                name="message"
                onChange={onChange}
                value={name}
                textVariant={Text.variants.medium}
                p={1}
                autoComplete="off"
                maxLength={usernameCharacterLimit}
              />
            </Alignment>
            <Button textVariant={Text.variants.large} type="submit" underline>
              change
            </Button>
          </Alignment>
        </form>
      )}
      <Box mt={2}>
        <Button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'hide' : 'change name'}
        </Button>
      </Box>
    </Box>
  )
}

ChangeName.propTypes = {
  setName: PropTypes.func.isRequired,
}

export default ChangeName
