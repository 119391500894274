import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import getRandomSyncer from 'helpers/getRandomSyncer'
import { useFirebase } from 'firebase-utils'
import { addYears } from 'date-fns'

const useAnonymousSyncer = () => {
  const firebase = useFirebase()
  const [{ syncer }, setCookie] = useCookies(['syncer'])
  const [currentSyncer, setCurrentSyncer] = useState({
    id: '',
    name: '',
    color: '',
    dateCreated: Date.now(),
  })

  useEffect(() => {
    const createSyncer = async () => {
      try {
        const randomUser = getRandomSyncer()

        await firebase.syncer.createSyncer(randomUser)

        setCookie('syncer', randomUser.id, {
          path: '/',
          expires: addYears(Date.now(), 10),
        })

        setCurrentSyncer(randomUser)
      } catch (error) {
        await firebase.error.insert(`error creating syncer: ${error.message}`)
      }
    }

    const getSyncer = async (syncerId) => {
      try {
        const result = await firebase.syncer.getSyncer(syncerId)

        if (result.exists) {
          setCurrentSyncer({ id: result.id, ...result.data() })
        }
      } catch (error) {
        await firebase.error.insert(`error fetchin syncer: ${error.message}`)
      }
    }

    if (!syncer || typeof syncer === 'object') {
      createSyncer()
    } else {
      getSyncer(syncer)
    }
  }, [syncer, setCookie, firebase])

  return currentSyncer
}

export default useAnonymousSyncer
