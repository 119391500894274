import React from 'react'
import { useTheme } from 'styled-components'
import ReactGiphySearchbox from 'react-giphy-searchbox'
import { toast } from 'react-toastify'

const copyToClipboard = (text) => {
  const dummy = document.createElement('textarea')
  document.body.appendChild(dummy)
  dummy.value = text
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

const GiphyReactions = () => {
  const copied = () => toast('giphy url copied')
  const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY
  const theme = useTheme()

  const onSelect = (item) => {
    const gifUrl = item.images.downsized.url.split('?')[0]

    copyToClipboard(gifUrl)
    copied()
  }

  return (
    <ReactGiphySearchbox
      apiKey={GIPHY_API_KEY}
      poweredByGiphy
      poweredByGiphyImage={theme.giphy.poweredByGiphyImageUrlSmall}
      onSelect={onSelect}
      masonryConfig={[
        { columns: 2, imageWidth: 110, gutter: 5 },
        { mq: '700px', columns: 3, imageWidth: 170, gutter: 5 },
      ]}
    />
  )
}

export default GiphyReactions
