import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import { getRoomId } from 'helpers/getRoom'
import prodConfig from './credentials/config'
import devConfig from './credentials/dev-config'
import Chat from './partial-classes/Chat'
import Room from './partial-classes/Room'
import Syncer from './partial-classes/Syncer'
import Auth from './partial-classes/Auth'
import FeatureSwitches from './partial-classes/FeatureSwitches'
import Schedule from './partial-classes/Schedule'
import Reaction from './partial-classes/Reaction'
import Settings from './partial-classes/Settings'
import Admins from './partial-classes/Admins'
import Error from './partial-classes/Error'
import Storage from './partial-classes/Storage'
import Queue from './partial-classes/Queue'
import Playlist from './partial-classes/Playlist'

const config =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? prodConfig : devConfig

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config)
      app.analytics()
    }

    this.authentication = app.auth()
    this.firestore = app.firestore()
    this.firebase_storage = app.storage()
    this.currentRoom = 'home'

    this.chat = new Chat(this.firestore, this.currentRoom)
    this.room = new Room(this.firestore, this.currentRoom)
    this.syncer = new Syncer(this.firestore, this.currentRoom)
    this.schedule = new Schedule(this.firestore, this.currentRoom)
    this.reaction = new Reaction(this.firestore, this.currentRoom)
    this.settings = new Settings(this.firestore, this.currentRoom)
    this.admins = new Admins(this.firestore, this.currentRoom)
    this.queues = new Queue(this.firestore, this.currentRoom)
    this.playlists = new Playlist(this.firestore, this.currentRoom)
    this.error = new Error(this.firestore, this.currentRoom)
    this.storage = new Storage(this.firebase_storage, this.currentRoom)

    this.auth = new Auth(this.authentication)
    this.featureSwitches = new FeatureSwitches()
  }

  setRoom = (roomName) => {
    const name = getRoomId(roomName)
    this.currentRoom = name

    this.error.setRoom(name)
    this.storage.setRoom(name)
    this.chat.setRoom(name)
    this.room.setRoom(name)
    this.syncer.setRoom(name)
    this.schedule.setRoom(name)
    this.reaction.setRoom(name)
    this.settings.setRoom(name)
    this.admins.setRoom(name)
    this.queues.setRoom(name)
    this.playlists.setRoom(name)
  }

  isHome = () => this.currentRoom === 'home'

  init = async (roomName) => {
    const name = getRoomId(roomName)

    try {
      const roomRef = await this.firestore.collection('rooms').doc(name).get()

      if (roomRef?.exists) {
        throw new Error('room exists already')
      }

      await this.room.init(name)
      await this.admins.init(name)
      await this.settings.init(name)
      await this.reaction.init(name)
      await this.queues.init(name)
    } catch (error) {
      await this.error.insert(`error creating room ${name}: ${error?.message}`)
    }
  }

  // ==== PLEASE DONT USE (just for testing) ====

  delete = async (roomName) => {
    if (this.featureSwitches.enableDeleting) {
      const name = getRoomId(roomName)

      try {
        await this.room.delete(roomName)
        await this.admins.delete(roomName)
        await this.settings.delete(roomName)
        await this.reaction.delete(roomName)
      } catch (error) {
        await this.error.insert(
          `error deleting room ${name}: ${error?.message}`
        )
      }
    }
  }
}

export default Firebase
