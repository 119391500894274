import { brutal } from 'rn-design-system'
import IMAGES from './images'
import COLORS from './colors'

const trueOrFalse = Math.random() < 0.5

// const generateBoxShadow = (height, color) =>
//   [...Array(height)]
//     .map((_, i) => i + 1)
//     .reverse()
//     .reduce((acc, b) => `${acc ? `${acc}, ` : ''}-${b}px -${b}px ${color}`, '')
const generateBoxShadow = (color) =>
  `-8px -8px ${color}, -7px -7px ${color}, -6px -6px ${color}, -5px -5px ${color}, -4px -4px ${color}, -3px -3px ${color}, -2px -2px ${color}, -1px -1px ${color}`

const theme = (version = 'dark') => {
  const dsTheme = brutal(version)

  const { colorRoles: dsColorRoles } = dsTheme

  const colorRoles = COLORS[version]

  return {
    ...dsTheme,
    colors: { ...dsColorRoles, ...colorRoles },

    button: {
      primary: {
        ...dsTheme.button.primary,
        position: 'relative',
        transition: 'all 0.1s linear 0s',
        top: 0,
        left: 0,
        '&:hover': {
          background: `url(${trueOrFalse ? IMAGES.VISUALS1 : IMAGES.VISUALS2})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          'box-shadow': generateBoxShadow(colorRoles.secondary),
          top: '8px',
          left: '8px',
        },
      },
      visuals: {
        ...dsTheme.button.primary,
        background: `url(${IMAGES.VISUALS1})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        transition: 'all 0.1s linear 0s',
        top: 0,
        left: 0,
        '&:hover': {
          background: `url(${IMAGES.VISUALS2})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          'box-shadow': generateBoxShadow(colorRoles.secondary),
          top: '8px',
          left: '8px',
        },
      },
    },

    linkButton: {
      '&:hover': {
        background: `url(${trueOrFalse ? IMAGES.VISUALS1 : IMAGES.VISUALS2})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
      },
    },

    modal: {
      overlay: {
        zIndex: 2,
        backgroundColor: 'transparent',
      },
      content: {
        background: dsColorRoles.secondary,
        border: `1px dashed ${dsColorRoles.primary}`,
      },
    },

    global: {
      ...dsTheme.global,
      cursor: `url(${IMAGES.CURSOR}), auto`,
    },

    live: {
      backgroundColor: '#ff4136',
    },

    logo: {
      logoUrl:
        version === 'dark'
          ? IMAGES.LOGO_WHITE_TRANSPARENT
          : IMAGES.LOGO_BLACK_TRANSPARENT,
      backgroundColorPrimary: colorRoles.primary,
      backgroundColorSecondary: colorRoles.secondary,
    },

    chat: {
      timestampColour: `#A9A9A9`,
    },

    schedule: {
      liveTimeColour: colorRoles.secondary,
    },

    switch: {
      handleDiameter: 25,
      offColor: '#000',
      onColor: '#000',
      offHandleColor: '#fff',
      onHandleColor: '#fff',
      border: `1px dashed ${dsColorRoles.primary}`,
    },

    bespoke: {
      clubQLink: {
        color: dsColorRoles.primary,
      },
    },

    giphy: {
      poweredByGiphyImageUrlSmall: IMAGES.POWERED_BY_GIPHY_SMALL,
      poweredByGiphyImageUrl: IMAGES.POWERED_BY_GIPHY,
    },
  }
}

export default theme
