import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Text, Alignment, Box } from 'rn-design-system'
import GridLayout from 'components/generic/grid-layout'
import AddMix from 'components/shared/add-mix'
import { useFirebase } from 'firebase-utils'
import DoubleCheckButton from 'components/generic/double-check-button/DoubleCheckButton'

const MAX_IN_PLAYLIST = 15

const PlaylistItemSettings = ({ playlist }) => {
  const [mixes, setMixes] = useState([])
  const { error, playlists } = useFirebase()

  useEffect(() => {
    const getMixes = async (mixesToGet) => {
      const playlistMixes = await mixesToGet.reduce(async (a, mix) => {
        const mixData = await mix.get()
        const acc = await a

        return [...acc, { id: mixData.id, ...mixData.data() }]
      }, [])

      setMixes(playlistMixes)
    }

    if (playlist.mixes && playlist.mixes.length > 0) {
      getMixes(playlist.mixes)
    }
  }, [playlist.mixes])

  const add = async ({ name, url, duration }) => {
    try {
      await playlists.addToPlaylist({
        name,
        url,
        duration,
        playlist: playlist.id,
      })
    } catch (err) {
      await error.insert(`Error adding to playlist, ${err.message}`)
    }
  }

  const addToQueue = async () => {
    try {
      await playlists.addPlaylistToQueue(playlist.id)
    } catch (err) {
      await error.insert(`Error adding to queue, ${err.message}`)
    }
  }

  return (
    <Alignment flexDirection="column" mt={3}>
      <Alignment mb={3} width="100%" justifyContent="space-between">
        {mixes && mixes.length < MAX_IN_PLAYLIST && (
          <AddMix onAnalyzed={add} addButtonText="add mix" />
        )}
        <Box ml={4}>
          <DoubleCheckButton
            buttonText="add playlist to queue"
            onSubmit={addToQueue}
          />
        </Box>
      </Alignment>
      <GridLayout>
        {mixes.map((mix, index) => (
          <PlaylistMix
            key={mix.id}
            position={index + 1}
            name={mix.name}
            url={mix.url}
          />
        ))}
      </GridLayout>
    </Alignment>
  )
}

PlaylistItemSettings.propTypes = {
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    room: PropTypes.string.isRequired,
    mixes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

const PlaylistMix = ({ name, url, position }) => (
  <Alignment>
    <Text variant={Text.variants.large}>{position}</Text>
    <Alignment ml={2} flexDirection="column">
      <Text variant={Text.variants.large}>{name}</Text>
      <Text>{url}</Text>
    </Alignment>
  </Alignment>
)

PlaylistMix.propTypes = {
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default PlaylistItemSettings
