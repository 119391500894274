import styled from 'styled-components'

const XTIME = 8
const YTIME = 9

const BouncingLogo = styled.img`
  display: block;
  background-image: radial-gradient(
    ${({ theme }) => theme.logo.backgroundColorPrimary} 0,
    transparent 70%
  );
  opacity: 0.8;
  z-index: 0;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;

  -webkit-animation: moveX${({ size }) =>
    size} ${XTIME}s linear 0s infinite alternate,
    moveY${({ size }) => size} ${YTIME}s linear 0s infinite alternate;
  -moz-animation: moveX${({ size }) =>
    size} ${XTIME}s linear 0s infinite alternate,
    moveY${({ size }) => size} ${YTIME}s linear 0s infinite alternate;
  -o-animation: moveX${({ size }) =>
    size} ${XTIME}s linear 0s infinite alternate,
    moveY${({ size }) => size} ${YTIME}s linear 0s infinite alternate;
  animation: moveX${({ size }) => size} ${XTIME}s linear 0s infinite alternate,
    moveY${({ size }) => size} ${YTIME}s linear 0s infinite alternate;

  @keyframes moveX${({ size }) => size} {
    from {
      left: 0;
    }
    to {
      left: calc(100% - ${({ size }) => size}px);
    }
  }

  @keyframes moveY${({ size }) => size} {
    from {
      top: 0;
    }
    to {
      top: calc(100% - ${({ size }) => size}px);
    }
  }
`

export default BouncingLogo
