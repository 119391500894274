import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import IMAGES from 'themes/brutal/images'
import LinkForm from './LinkForm'

const Layout = ({ children }) => (
  <Box
    display="grid"
    gridTemplateColumns={{
      s: 'repeat(2, 1fr)',
      m: 'repeat(3, 1fr)',
      l: 'repeat(4, 1fr)',
    }}
    gridGap={3}
    width="100%"
    height="100%"
    mb={3}
  >
    {children}
  </Box>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Visuals = ({ settings }) => {
  const firebase = useFirebase()

  const setLogo = async (values) => {
    try {
      await firebase.settings.update({ 'visuals.logo': values.link })
    } catch (error) {
      await firebase.error.insert(`error setting logo url: ${error?.message}`)
    }
  }

  const setBackground = async (values) => {
    try {
      await firebase.settings.update({ 'visuals.background': values.link })
    } catch (error) {
      await firebase.error.insert(
        `error setting background url: ${error?.message}`
      )
    }
  }

  const setTopLogo = async (values) => {
    try {
      await firebase.settings.update({ 'visuals.topLogo': values.link })
    } catch (error) {
      await firebase.error.insert(
        `error setting top logo url: ${error?.message}`
      )
    }
  }

  const setCursor = async (values) => {
    try {
      await firebase.settings.update({ 'visuals.cursor': values.link })
    } catch (error) {
      await firebase.error.insert(`error setting cursor url: ${error?.message}`)
    }
  }

  return (
    <Alignment flexDirection="column" width="100%" mt={3}>
      <Layout>
        <LinkForm
          description="(the floating image, needs to be square)"
          value={settings?.visuals?.logo}
          setLink={setLogo}
          reset={() =>
            setLogo({
              link: IMAGES.LOGO_WHITE_TRANSPARENT,
            })
          }
        />
        <LinkForm
          description="(page background)"
          value={settings?.visuals?.background}
          setLink={setBackground}
          reset={() =>
            setBackground({
              link: null,
            })
          }
        />
        <LinkForm
          description="(image above description)"
          value={settings?.visuals?.topLogo}
          setLink={setTopLogo}
          reset={() =>
            setTopLogo({
              link: null,
            })
          }
        />
        <LinkForm
          description="(cursor - max 30x30)"
          value={settings?.visuals?.cursor}
          setLink={setCursor}
          reset={() =>
            setCursor({
              link: IMAGES.CURSOR,
            })
          }
        />
      </Layout>
    </Alignment>
  )
}

Visuals.propTypes = {
  settings: PropTypes.shape({
    visuals: PropTypes.shape({
      logo: PropTypes.string,
      background: PropTypes.string,
      cursor: PropTypes.string,
      topLogo: PropTypes.string,
    }),
  }).isRequired,
}

export default Visuals
