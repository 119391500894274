import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import LoadingBar from 'react-top-loading-bar'
import styled, { useTheme } from 'styled-components'
import { Box } from 'rn-design-system'

const useLoadingBar = (start, finish) => {
  const ref = useRef()

  useEffect(() => {
    const loadingBar = ref.current
    if (loadingBar) {
      if (start) {
        loadingBar.continuousStart()
      } else if (finish) {
        loadingBar.complete()
      }
    }
  }, [ref, start, finish])

  return ref
}
const Progress = ({ start, finish }) => {
  const loadingRef = useLoadingBar(start, finish)
  const theme = useTheme()

  return (
    <StyledBox height={3}>
      <StyledLoadingBar
        ref={loadingRef}
        height={3}
        color={theme?.colors?.primary}
      />
    </StyledBox>
  )
}

Progress.propTypes = {
  start: PropTypes.bool.isRequired,
  finish: PropTypes.bool.isRequired,
}

const StyledBox = styled(Box)`
  position: relative;
  overflow-y: hidden;
`

const StyledLoadingBar = styled(LoadingBar)`
  position: relative !important;
`

export default Progress
