import { useState } from 'react'
import { useSettingsContext } from 'context/room-context'
import useInterval from 'hooks/useInterval'

const RANDOM_CHARACTERS = 'αβγδεζηθικλμνξοπρςστυφϒϖχψω'

const textRandomizer = (text) => {
  let result = ''
  for (let i = 0; i < text.length; i += 1) {
    const character = text[i]

    if (character === ' ') {
      result += ' '
    } else {
      result +=
        RANDOM_CHARACTERS[Math.floor(Math.random() * RANDOM_CHARACTERS.length)]
    }
  }
  return result
}

const useTextRandomizer = (text, time = 100) => {
  const [random, setRandom] = useState(text)
  const { settings } = useSettingsContext()

  useInterval(() => {
    if (settings?.visuals?.randomize) {
      const result = textRandomizer(text)

      setRandom(result)
    } else if (random !== text) {
      setRandom(text)
    }
  }, time)

  return random
}

export default useTextRandomizer
