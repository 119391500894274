import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Button, Text, Box } from 'rn-design-system'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { Instagram, Soundcloud } from '@styled-icons/fa-brands'
import LinkButton from 'components/generic/link-button'
import { useSettingsContext } from 'context/room-context'
import { useFirebase } from 'firebase-utils'
import PartnerLink from 'components/bespoke/partner-link'
import styled from 'styled-components'

const isHome = (name) => name === 'home'

const Rotate = styled(Box)`
  animation: rotateY 5s infinite linear;
  transform-origin: 4px 0;

  @keyframes rotateY {
    to {
      transform: rotateY(360deg);
    }
  }
`

const TM8 = () => (
  <PartnerLink key="clubq" href="/rooms/technomate">
    <Alignment>
      tm8&nbsp;
      <Rotate ml={2}>
        <span role="img" aria-label="satellite emoji">
          📡
        </span>
      </Rotate>
    </Alignment>
  </PartnerLink>
)

const Share = ({ name, link }) => {
  const copied = () => toast('url copied, share it wherever')
  const { settings } = useSettingsContext()
  const { currentRoom, featureSwitches } = useFirebase()

  return [
    !featureSwitches.partnerLive || currentRoom === 'technomate' ? (
      <CopyToClipboard
        key="share"
        text={`https://syncers.club${!isHome(name) ? link : ''}`}
        onCopy={copied}
      >
        <Button ml={{ s: 0, m: 3 }} onClick={() => ({})}>
          share
        </Button>
      </CopyToClipboard>
    ) : (
      <Alignment key="partner" ml={{ s: 0, m: 3 }}>
        <TM8 />
      </Alignment>
    ),
    <SocialLink
      key="instagram"
      href={
        settings?.links?.instagram || 'https://instagram.com/syncers.worldwide'
      }
      icon={Instagram}
    />,
    <SocialLink
      key="soundcloud"
      href={
        settings?.links?.soundcloud || 'https://soundcloud.com/syncersworldwide'
      }
      icon={Soundcloud}
    />,
  ]
}

Share.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
}

Share.defaultProps = {
  link: '/',
}

const SocialLink = ({ href, icon: Icon }) => (
  <Alignment ml={{ s: 1, m: 3 }}>
    <LinkButton target="_blank" href={href}>
      <Text>
        <Icon size={20} />
      </Text>
    </LinkButton>
  </Alignment>
)

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
}

export default Share
