import { useEffect, useState, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { useFirebase } from 'firebase-utils'
import useInterval from 'hooks/useInterval'

const useSyncingCount = () => {
  const firebase = useFirebase()
  const [{ syncer }] = useCookies(['syncer'])
  const [count, setCount] = useState(1)

  const getCount = useCallback(() => {
    let countRef

    if (firebase.isHome()) {
      countRef = firebase.syncer.getGlobalOnline
    } else {
      countRef = firebase.syncer.getOnline
    }

    countRef().then((users) => {
      setCount(users.size)
    })
  }, [firebase])

  useEffect(() => {
    if (syncer) {
      firebase.syncer.setOnline(syncer)
    }

    getCount()
  }, [syncer, firebase, getCount, firebase.currentRoom])

  useInterval(() => {
    if (syncer) {
      firebase.syncer.setOnline(syncer)
    }
  }, 60000)

  useInterval(() => {
    getCount()
  }, 240000)

  return count
}

export default useSyncingCount
