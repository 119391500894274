import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Alignment, Text, useInterval } from 'rn-design-system'
import { useSyncerContext } from 'context/syncer-context'
import { useMixContext, actions } from 'context/mix-context'
import { useRoomContext, useSettingsContext } from 'context/room-context'
import { useRoomAdminContext } from 'context/room-admin-context'
import { useFirebase } from 'firebase-utils'
import UpNextItem from './components/UpNextItem'
import AddUpNextItem from './components/AddUpNextItem'
import Decision from './components/Decision'
import usePoll from './usePoll'

const canDecide = (curated, isCurator, streamLive, hasFinished, url, poll) =>
  (!curated || isCurator) &&
  !streamLive &&
  (hasFinished || !url) &&
  poll.filter((x) => x.isSet).length > 0

const Poll = () => {
  const syncer = useSyncerContext()
  const { isCurator } = useRoomAdminContext()
  const {
    state: { hasFinished },
    dispatch,
  } = useMixContext()
  const {
    loading: roomLoading,
    room: { mix },
  } = useRoomContext()
  const {
    loading: settingsLoading,
    settings: { curated, switches },
  } = useSettingsContext()

  const [poll, decide] = usePoll(() => {
    dispatch({
      type: actions.SET_IS_PLAYING,
      payload: true,
    })
  })

  if (roomLoading || settingsLoading) {
    return null
  }

  return (
    <>
      <Alignment
        flexDirection="column"
        alignItems="center"
        width="100%"
        justifyContent="center"
      >
        <Heading curated={curated} isCurator={isCurator} />
        <Decision
          mt={3}
          poll={poll}
          decide={decide}
          canDecide={canDecide(
            curated,
            isCurator,
            switches?.streamLive,
            hasFinished,
            mix?.url,
            poll
          )}
        />
        {poll.map((item) => (
          <Alignment justifyContent="center" key={item.id} mt={3} width="100%">
            {item.isSet ? (
              <UpNextItem
                id={item.id}
                name={item.name}
                votes={item.votes}
                voted={poll.some((x) => x.votes.includes(syncer.id))}
              />
            ) : (
              <SetUpNextItem canSet={!curated || isCurator} id={item.id} />
            )}
          </Alignment>
        ))}
      </Alignment>
    </>
  )
}

const Heading = ({ curated, isCurator }) => {
  const { featureSwitches } = useFirebase()
  const [showHelp, setShow] = useState(false)

  useInterval(() => {
    setShow(!showHelp)
  }, 5000)

  return (
    <Alignment flexDirection="column" alignItems="center">
      <Text variant={Text.variants.large}>up next poll</Text>
      <Alignment mt={1}>
        {showHelp ? (
          <Text variant={Text.variants.small}>
            {featureSwitches.supportedPlatforms?.join(' ~/~ ')}
          </Text>
        ) : (
          <Alignment>
            {curated && !isCurator ? (
              <Text variant={Text.variants.small}>
                1. vote for a mix 2. listen to whats chosen 3. enjoi
              </Text>
            ) : (
              <Text variant={Text.variants.small}>
                1. find a mix 2. add it below 3. get some votes 4. enjoi
              </Text>
            )}
          </Alignment>
        )}
      </Alignment>
    </Alignment>
  )
}

Heading.propTypes = {
  curated: PropTypes.bool,
  isCurator: PropTypes.bool,
}

Heading.defaultProps = {
  curated: false,
  isCurator: false,
}

const SetUpNextItem = ({ canSet, id }) => {
  const [showForm, setShowForm] = useState(false)

  if (!canSet) {
    return null
  }

  return (
    <Alignment width="100%" justifyContent="center">
      {!showForm && <Button onClick={() => setShowForm(true)}>add</Button>}
      {showForm && <AddUpNextItem id={id} setShowForm={setShowForm} />}
    </Alignment>
  )
}

SetUpNextItem.propTypes = {
  id: PropTypes.string.isRequired,
  canSet: PropTypes.bool,
}

SetUpNextItem.defaultProps = {
  canSet: true,
}

export default Poll
