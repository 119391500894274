import { useFirebase } from 'firebase-utils'
import { useState, useEffect } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

const useQueueItem = (id) => {
  const [queueItem, setQueueItem] = useState({})
  const firebase = useFirebase()

  const [document, loading] = useDocument(
    firebase.firestore.doc(`queueItems/${id}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (!loading) {
      if (document?.exists) {
        const result = document.data()

        setQueueItem({ id: document.id, ...result })
      }
    }
  }, [document, setQueueItem, loading])

  return { loading, queueItem }
}

export default useQueueItem
