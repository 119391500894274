import React from 'react'
import { Alignment } from 'rn-design-system'
import Tabs from 'components/generic/tabs'
import { useSettingsContext } from 'context/room-context'
import ToggleChat from './toggle-chat'
import StreamSettings from './stream-settings'
import LinkSettings from './link-settings'
import HistorySettings from './history-settings'
import RoomAdminUsers from './room-admin-users'
import ToggleCurated from './toggle-curated'
import Reactions from './reactions'
import ClearMix from './clear-mix'
import FileManagement from './file-management'
import Visuals from './visuals'
import TogglePollQueue from './stream-settings/toggle-poll-queue'
import PlaylistSettings from './playlist-settings'
import PageSettings from './page-settings'

const RoomSettings = () => {
  const { settings } = useSettingsContext()

  return (
    <Alignment flexDirection="column" mt={3}>
      <Tabs defaultTab="general">
        <Tabs.Tab name="general">
          <TogglePollQueue queueLive={settings?.switches?.queueLive} />
          <ToggleCurated curated={settings?.curated} />
          <ToggleChat showChat={settings?.switches?.showChat} />
          <ClearMix />
          <LinkSettings settings={settings} />
        </Tabs.Tab>
        <Tabs.Tab name="page layout">
          <PageSettings />
        </Tabs.Tab>
        <Tabs.Tab name="stream">
          <StreamSettings settings={settings} />
        </Tabs.Tab>
        <Tabs.Tab name="visuals">
          <Visuals settings={settings} />
        </Tabs.Tab>
        <Tabs.Tab name="reactions">
          <Reactions />
        </Tabs.Tab>
        <Tabs.Tab name="files">
          <FileManagement />
        </Tabs.Tab>
        <Tabs.Tab name="users">
          <RoomAdminUsers />
        </Tabs.Tab>
        <Tabs.Tab name="history">
          <HistorySettings settings={settings} />
        </Tabs.Tab>
        <Tabs.Tab name="playlists">
          <PlaylistSettings />
        </Tabs.Tab>
      </Tabs>
    </Alignment>
  )
}

export default RoomSettings
