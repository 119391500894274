import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'rn-design-system'

const SpacedText = ({ children, variant }) =>
  children && (
    <Box mt={3}>
      <Text variant={variant}>{children}</Text>
    </Box>
  )

SpacedText.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string
}

SpacedText.defaultProps = {
  children: null,
  variant: Text.variants.medium
}

export default SpacedText
