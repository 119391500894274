import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import hasDocs from 'helpers/hasDocs'
import { Text, Alignment } from 'rn-design-system'
import Link from 'components/generic/link'
import { getRoomName } from 'helpers/getRoom'

const formatRooms = (docs) => {
  const reduced = docs.reduce((a, room) => {
    const data = room.data()

    return [
      ...a,
      {
        id: room.id,
        name: data.name,
        url: room.id === 'home' ? '/' : `/rooms/${room.id}`,
      },
    ]
  }, [])

  return reduced
}

const FeaturedRooms = ({ limit }) => {
  const { firestore } = useFirebase()
  const [collection] = useCollection(
    firestore
      .collection('rooms')
      .where('featured', '==', true)
      .orderBy('active')
      .orderBy('name')
      .limit(limit)
  )
  const [rooms, setRooms] = useState([])

  const setRoomsList = useCallback(
    (docs, merge) => {
      const reduced = formatRooms(docs)

      if (merge) {
        setRooms((x) => [...x, ...reduced])
      } else {
        setRooms(reduced)
      }
    },
    [setRooms]
  )

  useEffect(() => {
    if (hasDocs(collection)) {
      setRoomsList(collection.docs)
    }
  }, [collection, setRoomsList])

  return (
    <Alignment flexDirection="column" border="1px dashed">
      <Alignment
        justifyContent="center"
        width="100%"
        p={2}
        borderBottom="1px dashed"
      >
        <Text>our favourites</Text>
      </Alignment>
      {rooms.map((room) => (
        <Alignment
          justifyContent="space-between"
          key={room.id}
          my={2}
          px={2}
          borderBottom="1px dashed"
        >
          <Alignment>
            <Link href={room.url} textVariant={Text.variants.large}>
              {getRoomName(room.name)}&nbsp;&rarr;
            </Link>
          </Alignment>
        </Alignment>
      ))}
    </Alignment>
  )
}

FeaturedRooms.propTypes = {
  limit: PropTypes.number,
}

FeaturedRooms.defaultProps = {
  limit: 10,
}

export default FeaturedRooms
