import React from 'react'
import PropTypes from 'prop-types'
import LinkButton from 'components/generic/link-button'

const PartnerLink = ({ href, children }) => (
  <LinkButton href={href} to={href} p={children ? 0 : 2}>
    {children}
  </LinkButton>
)

PartnerLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

PartnerLink.defaultProps = {
  children: null,
}

export default PartnerLink
