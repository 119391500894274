import React from 'react'
import { Alignment, Button, Box } from 'rn-design-system'
import { useHeadingContext } from 'context/heading-context'
import MoreModal from 'components/shared/more-modal'
import SettingsModal from 'components/shared/settings-modal'
import styled from 'styled-components'
import LiveIndicator from './components/LiveIndicator'
import Share from './components/Share'
import Title from './components/Title'

const Heading = () => {
  const { state } = useHeadingContext()

  return (
    <Box mx={{ s: 0, m: 6 }}>
      <Alignment
        flexDirection={{ s: 'column', m: 'row' }}
        justifyContent="space-between"
        alignItems={{ s: 'flex-start', m: 'flex-end' }}
      >
        <Alignment>
          <Title
            name={state?.roomName}
            headerFont={state?.headerFont}
            link={state?.roomLink}
          />
        </Alignment>
        <Alignment my={{ s: 2, m: 0 }}>
          {state?.live && <LiveIndicator />}
          <Button onClick={() => window.location.reload()}>sync</Button>
          <HiddenAlignment display={{ s: 'none', m: 'flex' }}>
            <Share name={state?.roomName} link={state?.roomLink} />
          </HiddenAlignment>
          <Box ml={{ s: 1, m: 3 }}>
            <MoreModal />
          </Box>
          <Box ml={{ s: 1, m: 3 }}>
            <SettingsModal roomName={state?.roomName} />
          </Box>
        </Alignment>
        <HiddenAlignment display={{ m: 'none' }}>
          <Share name={state?.roomName} />
        </HiddenAlignment>
      </Alignment>
    </Box>
  )
}

const HiddenAlignment = styled(Alignment)`
  display: ${(props) => props.display};
`

export default Heading
