import React, { createContext, useReducer, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'

const HeadingContext = createContext()

const actions = {
  SET_HEADING: 'SET_HEADING',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_HEADING:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const HeadingProvider = ({ children }) => {
  const initialState = {
    roomName: 'home',
    live: false,
    link: '/',
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <HeadingContext.Provider value={contextValue}>
      {children}
    </HeadingContext.Provider>
  )
}

HeadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useHeadingContext = () => useContext(HeadingContext)

export { HeadingProvider, useHeadingContext, actions }
