import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Text } from 'rn-design-system'
import Chat from 'components/shared/chat'
import Layout, { Areas } from 'components/generic/layout'
import HistoryTicker from 'components/shared/history/history-ticker'
import HistoryList from 'components/shared/history/history-list'
import { useRoomContext, useSettingsContext } from 'context/room-context'
import Image from 'components/generic/image'
import FeaturedRooms from 'components/shared/featured-rooms'
import styled from 'styled-components'
import TOP_RIGHT from 'enums/layout/top-right'
import Link from 'components/generic/link'
import Center from './components/Center'
import DoesntExist from './components/DoesntExist'
import YoutubeEmbed from './components/YoutubeEmbed'

const TopRightComponent = ({ settings }) => {
  switch (settings?.layout?.topRight) {
    case TOP_RIGHT.DESCRIPTION:
      return <Description description={settings?.description} />
    case TOP_RIGHT.YOUTUBE_EMBED:
      return <YoutubeEmbed url={settings?.links?.youtubeEmbed} />
    case TOP_RIGHT.FEATURED:
      return <FeaturedRooms />
    default:
      return null
  }
}

TopRightComponent.propTypes = {
  settings: PropTypes.shape({
    description: PropTypes.string,
    layout: PropTypes.shape({
      topRight: PropTypes.string.isRequired,
    }),
    links: PropTypes.shape({
      youtubeEmbed: PropTypes.string,
    }),
  }).isRequired,
}

const Room = () => {
  const { room, loading } = useRoomContext()
  const { settings, loading: settingsLoading } = useSettingsContext()

  if (loading || settingsLoading) {
    return null
  }

  if (!loading && !room.exists) {
    return <DoesntExist />
  }

  return (
    <Layout>
      <Grid.Area area={Areas.Left} mt={3}>
        <Chat />
      </Grid.Area>
      <Grid.Area area={Areas.Center} mt={3}>
        <Center
          queueLive={settings?.switches?.queueLive}
          currentRoom={room.id}
        />
      </Grid.Area>
      <Grid.Area area={Areas.Right} mt={3}>
        {settings?.visuals?.topLogo && (
          <Box my={1}>
            <Image src={settings?.visuals?.topLogo} />
          </Box>
        )}
        <TopRightComponent settings={settings} />
        {settings?.links?.website && (
          <Box mt={3} border="1px dashed" p={2}>
            <Link target="_blank" underline href={settings?.links?.website}>
              {settings?.links?.website}
            </Link>
          </Box>
        )}
        <Box mt={3}>
          {settings?.switches?.historyTicker ? (
            <HistoryTicker
              headerText="recently played"
              limit={10}
              currentRoom={room?.name}
            />
          ) : (
            <HistoryList
              headerText="recently played"
              limit={10}
              currentRoom={room?.name}
            />
          )}
        </Box>
      </Grid.Area>
    </Layout>
  )
}

const Description = ({ description }) => {
  if (!description) {
    return null
  }

  return (
    <Box border="1px dashed" mb={3}>
      <PitHover p={2}>
        <Text>{description}</Text>
      </PitHover>
    </Box>
  )
}

Description.propTypes = {
  description: PropTypes.string,
}

Description.defaultProps = {
  description: '',
}

const generateBoxShadow = (color) => `8px 8px ${color}`

const PitHover = styled(Box)`
  position: relative;
  transition: all 0.1s linear 0s;
  top: 0px;
  left: 0px;

  &:hover {
    box-shadow: ${({ theme }) => generateBoxShadow(theme.colors.primary)};
    top: -8px;
    left: -8px;
    border-top: none;
    border-left: none;

    &::after {
      content: '';
      width: 1px;
      height: 11px;
      transform: rotate(-45deg);
      background-color: black;

      position: absolute;
      bottom: -9px;
      right: -4px;
    }
  }
`

export default Room
