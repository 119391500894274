import React from 'react'
import { Route } from 'react-router-dom'
import { Master } from 'rn-design-system'
import Room from 'sections/site/room'
import RoomAdmin from 'sections/admin/room-admin'
import SystemAdmin from 'sections/admin/system-admin'
import { RoomRoute } from 'context/room-context'
import { SyncerProvider } from 'context/syncer-context'
import { HeadingProvider } from 'context/heading-context'
import { UserProvider } from 'context/user-context'
// import Settings from 'components/shared/settings-modal/Settings'

const URL_PARAMS = {
  ROOM_NAME: 'roomName',
}

const Routes = () => (
  <Master mx={{ s: 0, m: 3 }} mb={3}>
    <UserProvider>
      <SyncerProvider>
        <HeadingProvider>
          <RoomRoute exact path="/" component={Room} />
          <RoomRoute
            path={`/rooms/:${URL_PARAMS.ROOM_NAME}?`}
            component={Room}
          />
          {/* <RoomRoute
            path={`/settings/:${URL_PARAMS.ROOM_NAME}?`}
            component={Settings}
          /> */}
          <RoomRoute
            path={`/secret-rooms/:${URL_PARAMS.ROOM_NAME}?`}
            component={Room}
          />
          <RoomRoute
            path={`/system-admin/rooms/:${URL_PARAMS.ROOM_NAME}/:tab?`}
            component={RoomAdmin}
          />
          <Route exact path="/system-admin/:tab?" component={SystemAdmin} />
        </HeadingProvider>
      </SyncerProvider>
    </UserProvider>
  </Master>
)

export default Routes
