import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'
import { Link as DsLink } from 'rn-design-system'

const Link = ({ noStyle, children, href, textVariant, underline, ...props }) =>
  noStyle ? (
    <StyledReactLink to={href} href={href}>
      {children}
    </StyledReactLink>
  ) : (
    <StyledReactLink to={href} href={href}>
      <DsLink
        href={href}
        textVariant={textVariant}
        underline={underline}
        {...props}
        as="span"
      >
        {children}
      </DsLink>
    </StyledReactLink>
  )

const StyledReactLink = styled(ReactLink)`
  text-decoration: none;
`

Link.propTypes = {
  noStyle: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  textVariant: PropTypes.string,
  underline: PropTypes.bool,
}

Link.defaultProps = {
  noStyle: false,
  textVariant: null,
  underline: false,
}

export default Link
