import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DoubleCheckButton from 'components/generic/double-check-button'
import Loading from 'components/shared/loading'
import useRequest from 'firebase-utils/server-access/useRequest'

const RemoveUser = ({ uid, accessType }) => {
  const [loading, setLoading] = useState(false)
  const removeUser = useRequest('/users/remove-user')

  const onSubmit = async () => {
    setLoading(true)
    await removeUser({ uid, accessType })
    setLoading(false)
  }

  return !loading ? (
    <DoubleCheckButton buttonText="remove" onSubmit={onSubmit} />
  ) : (
    <Loading m={2} loading={loading} />
  )
}

RemoveUser.propTypes = {
  uid: PropTypes.string.isRequired,
  accessType: PropTypes.string.isRequired,
}

export default RemoveUser
