import React from 'react'
import { Button } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'

const LogoutButton = () => {
  const { auth, error } = useFirebase()

  const logout = async () => {
    try {
      await auth.signOut()
    } catch (err) {
      await error.insert(`error logging out: ${err?.message}`)
    }
  }

  return <Button onClick={() => logout()}>logout</Button>
}

export default LogoutButton
