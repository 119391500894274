import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text } from 'rn-design-system'
import Link from 'components/generic/link'

const Nav = ({ selectedTab }) => (
  <Alignment
    justifyContent={{ s: 'space-between', m: '' }}
    flexDirection={{ s: 'row', m: 'column' }}
    mt={{ s: 3, m: 0 }}
    borderRight="1px dashed"
    height="100%"
  >
    <NavLink selectedTab={selectedTab} tab="rooms" />
  </Alignment>
)

Nav.propTypes = {
  selectedTab: PropTypes.string.isRequired,
}

const NavLink = ({ selectedTab, tab }) => (
  <Alignment mb={{ s: 0, m: 3 }} alignItems="flex-end">
    <Link
      textVariant={Text.variants.large}
      href={`/system-admin/${tab}`}
      underline={selectedTab === tab}
    >
      {tab}
    </Link>
  </Alignment>
)

NavLink.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  tab: PropTypes.string,
}

NavLink.defaultProps = {
  tab: 'rooms',
}

export default Nav
