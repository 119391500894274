import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text, Box } from 'rn-design-system'
import Switch from 'react-switch'
import styled, { useTheme } from 'styled-components'
import { useFirebase } from 'firebase-utils'
import { border } from 'styled-system'

const ToggleStream = ({ streamLive }) => {
  const { settings, error } = useFirebase()
  const theme = useTheme()

  const handleChange = async (value) => {
    try {
      const switches = {
        'switches.streamLive': value,
      }

      if (value) {
        switches['switches.queueLive'] = false
      }

      await settings.update(switches)
    } catch (err) {
      await error.insert(`error setting stream live: ${err?.message}`)
    }
  }

  return (
    <Alignment alignItems="center">
      <Box mr={2}>
        <Text variant={Text.variants.large}>stream live:</Text>
      </Box>
      <StyledSwitch
        {...theme.switch}
        onChange={handleChange}
        checked={streamLive}
      />
    </Alignment>
  )
}

ToggleStream.propTypes = {
  streamLive: PropTypes.bool.isRequired,
}

const StyledSwitch = styled(Switch)`
  ${border};
`

export default ToggleStream
