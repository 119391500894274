import React from 'react'
import Description from 'components/shared/description'
import Modal from 'components/generic/modal'
import styled from 'styled-components'
import { Link, Box, Text } from 'rn-design-system'

const MoreModal = () => (
  <Modal buttonText="us" title="syncers worldwide">
    <Description />
    <Footer p={2}>
      <Thing />
      <Link
        textVariant={Text.variants.small}
        href="https://ruar.studio"
        target="_blank"
      >
        made by ruar
      </Link>
    </Footer>
  </Modal>
)

const Footer = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const Thing = styled.div`
  pointer-events: none;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: blue;
  animation: moveX 3s linear 0s infinite alternate,
    moveY 4s linear 0s infinite alternate;

  @keyframes moveX {
    from {
      left: 0;
    }
    to {
      left: calc(100% - 10px);
    }
  }

  @keyframes moveY {
    from {
      top: 0;
    }
    to {
      top: calc(100% - 10px);
    }
  }
`

export default MoreModal
