import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from 'context/user-context'
import useAdmins from './useAdmins'

const RoomAdminContext = createContext()

const useRoomAdminContext = () => useContext(RoomAdminContext)

const ROLES = {
  MANAGERS: 'managers',
  CURATORS: 'curators',
}

const isAuthorized = (admins, userId, authenticated, isAdmin) => {
  if (!authenticated) {
    return {
      isManager: false,
      isCurator: false,
      authorized: false,
    }
  }

  const isManager = admins?.[ROLES.MANAGERS]?.includes(userId)
  const isCurator = admins?.[ROLES.CURATORS]?.includes(userId)

  const authorized = isManager || isCurator || isAdmin

  return {
    authorized: !!authorized,
    isManager: !!(isManager || isAdmin),
    isCurator: !!(isCurator || isManager || isAdmin),
  }
}

const RoomAdminProvider = ({ children, roomName }) => {
  const { admins, loading: adminsLoading } = useAdmins(roomName)
  const {
    user,
    authenticated,
    loading: userLoading,
    isAdmin,
  } = useUserContext()

  const authorizedResult = useMemo(
    () => isAuthorized(admins, user?.uid, authenticated, isAdmin),
    [admins, user, authenticated, isAdmin]
  )

  return (
    <RoomAdminContext.Provider
      value={{
        managers: admins?.managers || [],
        curators: admins?.curators || [],
        loading: adminsLoading || userLoading,
        ...authorizedResult,
      }}
    >
      {children}
    </RoomAdminContext.Provider>
  )
}

RoomAdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
  roomName: PropTypes.string.isRequired,
}

export { RoomAdminProvider, useRoomAdminContext }
