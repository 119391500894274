import { addMinutes } from 'date-fns'

class Syncer {
  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  createSyncer = (syncer) => {
    const batch = this.firestore.batch()

    const syncerRef = this.firestore.collection('syncers').doc(syncer.id)

    batch.set(syncerRef, syncer)

    return batch.commit()
  }

  updateSyncerName = (name, syncer) => {
    const batch = this.firestore.batch()

    const syncerRef = this.firestore.collection('syncers').doc(syncer.id)

    batch.set(syncerRef, { name }, { merge: true })

    return batch.commit()
  }

  getSyncer = (id) => {
    const syncer = this.firestore.collection('syncers').doc(id)

    return syncer.get()
  }

  getGlobalOnline = () => {
    const onlineSyncersRef = this.firestore.collection('onlineSyncers')

    const timeAgo = addMinutes(Date.now(), -10).getTime()

    return onlineSyncersRef.where('dateLastOnline', '>', timeAgo).get()
  }

  getOnline = (time) => {
    const onlineSyncersRef = this.firestore.collection('onlineSyncers')

    const timeAgo = time || addMinutes(Date.now(), -10).getTime()

    return onlineSyncersRef
      .where('dateLastOnline', '>', timeAgo)
      .where('room', '==', this.currentRoom)
      .get()
  }

  setOnline = (id) =>
    this.firestore.collection('onlineSyncers').doc(id).set(
      {
        room: this.currentRoom,
        dateLastOnline: Date.now(),
      },
      { merge: true }
    )
}

export default Syncer
