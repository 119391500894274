import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useFirebase } from 'firebase-utils'
import useIsAdmin from './useIsAdmin'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const firebase = useFirebase()
  const [user, loading, error] = useAuthState(firebase.authentication)
  const { loading: adminLoading, isAdmin } = useIsAdmin(user, loading)

  return (
    <UserContext.Provider
      value={{
        user,
        loading: loading || adminLoading,
        error,
        authenticated: !loading && !!user,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useUserContext = () => useContext(UserContext)

export { UserProvider, useUserContext }
