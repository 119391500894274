import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'rn-design-system'

const sGTA = `
    "center"
    "left"
    "right"
`

const mGTA = `
    "left center center right"
`

const Areas = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
}

const Layout = ({ children }) => (
  <Grid sCols={1} sGTA={sGTA} mCols={4} mGTA={mGTA} width="100%">
    {children}
  </Grid>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout as default, Areas }
