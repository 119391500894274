import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/generic/modal'
import PlaylistItemSettings from './PlaylistItemSettings'

const PlaylistItemModal = ({ title, playlist }) => (
  <Modal buttonText={title} title={title} closeText="&larr;">
    <PlaylistItemSettings playlist={playlist} />
  </Modal>
)

PlaylistItemModal.propTypes = {
  title: PropTypes.string.isRequired,
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    room: PropTypes.string.isRequired,
    mixes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default PlaylistItemModal
