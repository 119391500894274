import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import useRoom from './useRoom'
import useSettings from './useSettings'

const RoomContext = createContext()
const SettingsContext = createContext()

const RoomProvider = ({ children, roomName }) => {
  const { loading, room } = useRoom(roomName)
  const { loading: settingsLoading, settings } = useSettings(roomName)

  return (
    <RoomContext.Provider value={{ loading, room }}>
      <SettingsContext.Provider value={{ loading: settingsLoading, settings }}>
        {children}
      </SettingsContext.Provider>
    </RoomContext.Provider>
  )
}

RoomProvider.propTypes = {
  children: PropTypes.node.isRequired,
  roomName: PropTypes.string.isRequired,
}

const useRoomContext = () => useContext(RoomContext)
const useSettingsContext = () => useContext(SettingsContext)

export { RoomProvider, useRoomContext, useSettingsContext }
