import React from 'react'
import { Alignment } from 'rn-design-system'
import { useRoomAdminContext } from 'context/room-admin-context'
import { useUserContext } from 'context/user-context'
import RoomAdminUsersList from './room-admin-users-list'

const ACCESS_TYPES = {
  MANAGER: 'MANAGER',
  CURATOR: 'CURATOR',
}

const RoomAdminUsers = () => {
  const { isAdmin } = useUserContext()
  const { curators, managers, loading } = useRoomAdminContext()

  if (loading) {
    return null
  }

  return (
    <Alignment flexDirection="column">
      <RoomAdminUsersList
        title="curators"
        accessType={ACCESS_TYPES.CURATOR}
        userIds={curators}
        pageSize={5}
      />
      {isAdmin && (
        <RoomAdminUsersList
          title="managers"
          accessType={ACCESS_TYPES.MANAGER}
          userIds={managers}
          pageSize={5}
        />
      )}
    </Alignment>
  )
}

export default RoomAdminUsers
