import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Alignment } from 'rn-design-system'
import { variant } from 'styled-system'
import { Link as ReactLink } from 'react-router-dom'

const UnstyledReactLink = styled(ReactLink)`
  text-decoration: none;
  color: inherit;
`

const UnstyledExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const LinkButton = ({ children, href, ...props }) => {
  const external = href.includes('http')
  const LinkToUse = external ? UnstyledExternalLink : UnstyledReactLink

  return (
    <StyledAlignment
      p={1}
      px={2}
      border="1px dashed"
      alignItems="center"
      {...props}
    >
      <LinkToUse to={href} href={href} target={external ? '_blank' : undefined}>
        {children}
      </LinkToUse>
    </StyledAlignment>
  )
}

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

LinkButton.defaultProps = {
  variant: 'primary',
}

const StyledAlignment = styled(Alignment)`
  ${variant({ key: 'button' })};
`

export default LinkButton
