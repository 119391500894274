import React from 'react'
import { Alignment } from 'rn-design-system'
import { useSettingsContext } from 'context/room-context'
import TopRight from './layout-settings/TopRight'
import DescriptionSettings from './description-settings'
import PageSettingsLayout from './PageSettingsLayout'
import YoutubeEmbedSettings from './youtube-embed-settings'

const PageSettings = () => {
  const { settings, loading } = useSettingsContext()

  if (loading) {
    return null
  }

  const { description, layout, links } = settings

  return (
    <Alignment flexDirection="column" mt={3}>
      <PageSettingsLayout>
        <TopRight topRight={layout?.topRight} />
        <Alignment flexDirection="column">
          <DescriptionSettings description={description} />
          <YoutubeEmbedSettings value={links?.youtubeEmbed} />
        </Alignment>
      </PageSettingsLayout>
    </Alignment>
  )
}

export default PageSettings
