import React from 'react'
import PropTypes from 'prop-types'
import { useFirebase } from 'firebase-utils'
import { Button } from 'rn-design-system'
import { useSyncerContext } from 'context/syncer-context'
import styled from 'styled-components'
import { useRoomAdminContext } from 'context/room-admin-context'

const ReactionButton = ({ reaction, name }) => {
  const { chat, error } = useFirebase()
  const syncer = useSyncerContext()
  const { authorized } = useRoomAdminContext()

  const onClick = async () => {
    try {
      await chat.sendMessage({
        message: reaction.emoji,
        syncer,
        name,
        curator: authorized,
      })

      await reaction.activate()
    } catch (err) {
      await error.insert(`error sending reaction: ${err?.message}`)
    }
  }

  return (
    <StyledButton p={0} onClick={onClick}>
      {reaction.emoji}
    </StyledButton>
  )
}

ReactionButton.propTypes = {
  reaction: PropTypes.shape({
    emoji: PropTypes.string.isRequired,
    activate: PropTypes.func.isRequired,
    live: PropTypes.bool.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
}

const StyledButton = styled(Button)`
  border: none;
  border-radius: 100%;
  padding: 0;

  &:hover {
    top: 0px;
    left: 0px;
    box-shadow: none;
  }
`

export default ReactionButton
