import React, { useState } from 'react'
import { Box } from 'rn-design-system'
import DoubleCheckButton from 'components/generic/double-check-button'
import Loading from 'components/shared/loading'
import { useFirebase } from 'firebase-utils'

const ClearMix = () => {
  const [loading, setLoading] = useState(false)
  const firebase = useFirebase()

  const onSubmit = async () => {
    setLoading(true)
    await firebase.room.clearNowPlaying()
    setLoading(false)
  }

  return !loading ? (
    <Box mt={3}>
      <DoubleCheckButton buttonText="clear mix playing" onSubmit={onSubmit} />
    </Box>
  ) : (
    <Loading m={3} loading={loading} />
  )
}

export default ClearMix
