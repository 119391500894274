import React from 'react'
import PropTypes from 'prop-types'
import { IntersectingCirclesSpinner } from 'react-epic-spinners'
import { Box } from 'rn-design-system'

const Loading = ({ size, loading, ...props }) =>
  loading && (
    <Box {...props}>
      <IntersectingCirclesSpinner size={size} />
    </Box>
  )

Loading.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool,
}

Loading.defaultProps = {
  size: 16,
  loading: false,
}

export default Loading
