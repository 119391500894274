import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Input, Button, Text } from 'rn-design-system'
import { useForm } from 'react-hook-form'

const HiddenFormInput = ({
  type,
  name,
  placeholder,
  onSubmit,
  defaultValues = {},
  formErrors,
  maxLength,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alignment alignItems="flex-end" width="100%">
        <Alignment
          borderLeft="1px dashed"
          borderTop="1px dashed"
          height="40px"
          flexDirection="column"
          justifyContent="flex-end"
          width="100%"
        >
          <Input
            required
            type={type}
            placeholder={placeholder}
            name={name}
            ref={register({
              required: true,
              maxLength,
            })}
            textVariant={Text.variants.medium}
            p={1}
            autoComplete="off"
          />
        </Alignment>
        <Button textVariant={Text.variants.large} type="submit" underline>
          &gt;
        </Button>
      </Alignment>
      {(errors.email || errors.password || formErrors) && (
        <Text>something went wrong, please try again</Text>
      )}
    </form>
  )
}

HiddenFormInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape(),
  formErrors: PropTypes.shape(),
  maxLength: PropTypes.number,
}

HiddenFormInput.defaultProps = {
  defaultValues: {},
  formErrors: null,
  maxLength: 80,
}

export default HiddenFormInput
