import styled, { css } from 'styled-components'
import { Button } from 'rn-design-system'

const addBlink = css`
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;

  @keyframes blink {
    0% {
      background-color: #ff4136;
    }
    50% {
      background-color: black;
    }
    100% {
      background-color: #ff4136;
    }
  }
`

const MuteButton = styled(Button)`
  align-self: flex-end;
  border: none;
  border-radius: 100%;
  padding: 0;

  &:hover {
    top: 0px;
    left: 0px;
    box-shadow: none;
  }

  ${({ shouldBlink }) => shouldBlink && addBlink};
`

export default MuteButton
