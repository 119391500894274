import React from 'react'
import PropTypes from 'prop-types'
import { Alignment } from 'rn-design-system'
import useRequest from 'firebase-utils/server-access/useRequest'
import { useFirebase } from 'firebase-utils'
import { useRoomAdminContext } from 'context/room-admin-context'
import DoubleCheckButton from 'components/generic/double-check-button'
import { useSettingsContext } from 'context/room-context'
import AddMix from 'components/shared/add-mix'

const QueueControls = ({ firstQueueId, hideAdd }) => {
  const {
    settings: { curated },
  } = useSettingsContext()
  const { isCurator } = useRoomAdminContext()
  const addToQueue = useRequest('/rooms/add-to-queue')
  const skip = useRequest('/rooms/play-next-in-queue')
  const { error } = useFirebase()

  const add = async ({ name, url, duration }) => {
    try {
      await addToQueue({
        name,
        url,
        duration,
      })
    } catch (err) {
      await error.insert(`Error adding to queue, ${err.message}`)
    }
  }

  return (
    <Alignment width="100%" flexDirection="column" alignItems="center" mt={2}>
      {firstQueueId && isCurator && (
        <Alignment mb={2}>
          <DoubleCheckButton
            buttonText="skip"
            onSubmit={() => skip({ queueItemId: firstQueueId })}
          />
        </Alignment>
      )}
      {(!curated || isCurator) && !hideAdd && (
        <AddMix onAnalyzed={add} addButtonText="add to queue" />
      )}
    </Alignment>
  )
}

QueueControls.propTypes = {
  firstQueueId: PropTypes.string,
  hideAdd: PropTypes.bool,
}

QueueControls.defaultProps = {
  firstQueueId: undefined,
  hideAdd: false,
}

export default QueueControls
