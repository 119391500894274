import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import HiddenForm from 'components/generic/hidden-form'
import { useFirebase } from 'firebase-utils'

const YoutubeEmbedSettings = ({ value }) => {
  const { settings } = useFirebase()

  const setLink = async ({ link }) => {
    try {
      await settings.update({ 'links.youtubeEmbed': link })
    } catch (error) {
      await error.insert(`error setting youtube embed link: ${error?.message}`)
    }
  }

  return (
    <>
      <Alignment mt={3}>
        <Text color={value ? undefined : 'tertiary'}>
          {value || 'set youtube embed link 👇'}
        </Text>
        <Box mx={3}>
          <Text>/</Text>
        </Box>
        <Text>youtube embed</Text>
      </Alignment>
      <Alignment flexDirection={{ s: 'column', m: 'row' }} mt={2}>
        <HiddenForm
          type="url"
          name="link"
          placeholder="youtube embed link"
          onSubmit={setLink}
          buttonText="change link"
          horizontal
        />
      </Alignment>
    </>
  )
}

YoutubeEmbedSettings.propTypes = {
  value: PropTypes.string,
}

YoutubeEmbedSettings.defaultProps = {
  value: '',
}

export default YoutubeEmbedSettings
