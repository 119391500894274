import { firestore as firebaseFirestore } from 'firebase/app'

class Chat {
  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  sendMessage = ({ message, syncer, name, curator = false }) => {
    const batch = this.firestore.batch()

    const messageRef = this.firestore.collection('chat').doc()

    batch.set(messageRef, {
      message,
      syncerId: syncer.id,
      syncerName: name || syncer.name,
      syncerColor: syncer.color,
      curator,
      dateCreated: firebaseFirestore.Timestamp.now(),
      room: this.currentRoom,
    })

    return batch.commit()
  }
}

export default Chat
