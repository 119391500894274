import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Alignment, Input, Text, ButtonLink } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import useRequest from 'firebase-utils/server-access/useRequest'

const AddUpNextItem = ({ id, setShowForm }) => {
  const addUpNext = useRequest('/rooms/add-mix')
  const { featureSwitches } = useFirebase()
  const { register, handleSubmit, errors, reset, formState } = useForm()
  const [addErrors, setErrors] = useState()
  const [errorMessage, setErrorMessage] = useState('')

  const formatUrl = (url) => {
    return url.replace('https://m.soundcloud.com', 'https://soundcloud.com')
  }

  const add = async ({ name, url }) => {
    try {
      if (
        ReactPlayer.canPlay(url) &&
        featureSwitches.supportedPlatforms?.some((x) => url.includes(x))
      ) {
        const result = await addUpNext({
          url: formatUrl(url),
          name,
          upNextId: id,
        })

        if (result?.error) {
          setErrorMessage(
            'that mix has been played before, get something new on!'
          )
        } else {
          reset()
          setShowForm(false)
        }
      } else {
        setErrorMessage(
          `cant play that url sorry, please use a valid ${featureSwitches.supportedPlatforms?.join(
            ', '
          )} link`
        )
      }
    } catch (error) {
      setErrors(error)
    }
  }

  const { isSubmitting } = formState

  return (
    <Alignment
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <StyledForm onSubmit={handleSubmit(add)}>
        <Alignment flexDirection="column" alignItems="center" width="100%">
          <Input
            type="name"
            placeholder="mix name"
            name="name"
            ref={register({
              required: true,
              maxLength: 500,
            })}
            mb={3}
            width="100%"
          />
          <Input
            type="url"
            placeholder="url"
            name="url"
            ref={register({
              required: true,
              maxLength: 500,
            })}
            mb={3}
            width="100%"
          />
          <Alignment mb={2} alignItems="flex-end">
            <ButtonLink
              type="button"
              underline
              onClick={() => setShowForm(false)}
            >
              actually nah
            </ButtonLink>
            <Button disabled={isSubmitting} ml={3} type="submit">
              roll the dice
            </Button>
          </Alignment>
          {(errors.url || errors.name || addErrors) && (
            <Text color="#EA3C53">something went wrong, please try again</Text>
          )}
          {errorMessage && <Text color="#EA3C53">{errorMessage}</Text>}
        </Alignment>
      </StyledForm>
    </Alignment>
  )
}

AddUpNextItem.propTypes = {
  id: PropTypes.string.isRequired,
  setShowForm: PropTypes.func.isRequired,
}

const StyledForm = styled.form`
  width: 100%;
`

export default AddUpNextItem
