import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text } from 'rn-design-system'
import styled, { useTheme, css } from 'styled-components'
import useTextRandomizer from 'hooks/useTextRandomizer'
import { resultForSyncer, timestampFormatted } from './helpers'

const Message = ({ message, syncer }) => {
  const theme = useTheme()
  const text = useTextRandomizer(message.message)

  return (
    <Alignment flexDirection="column" pb={2} px={1} key={message.dateCreated}>
      <Alignment
        flexDirection="row"
        justifyContent={
          message.syncerId === syncer.id ? 'flex-end' : 'space-between'
        }
      >
        {syncer.id !== message.syncerId && (
          <SyncerName
            textAlign="end"
            color={message.syncerColor}
            variant={Text.variants.small}
            curator={message.curator}
          >
            {message.syncerName}
          </SyncerName>
        )}
        <Text color={theme.chat.timestampColour} variant={Text.variants.small}>
          {timestampFormatted(message.dateCreated)}
        </Text>
      </Alignment>
      <TextWrap
        textAlign={message.syncerId === syncer.id ? 'end' : 'start'}
        color={resultForSyncer(syncer, message, syncer.color, undefined)}
      >
        {text}
      </TextWrap>
    </Alignment>
  )
}

Message.propTypes = {
  message: PropTypes.shape({
    dateCreated: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    syncerId: PropTypes.string.isRequired,
    syncerColor: PropTypes.string.isRequired,
    curator: PropTypes.bool,
    syncerName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  syncer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
}

const TextWrap = styled(Text)`
  word-wrap: break-word;
  hyphens: auto;
  text-align: ${(props) => props.textAlign};
`

const COLORS = [
  '#0074D9',
  '#7FDBFF',
  '#39CCCC',
  '#3D9970',
  '#2ECC40',
  '#01FF70',
  '#FFDC00',
  '#FF851B',
  '#FF4136',
  '#85144b',
  '#F012BE',
  '#B10DC9',
].join(', ')

const SyncerName = styled(Text)`
  ${({ curator }) =>
    curator &&
    css`
      background-image: linear-gradient(to left, ${COLORS});
      -webkit-background-clip: text;
      color: transparent;
    `}
`

export default Message
