import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase } from 'firebase-utils'
import { Button, Alignment, Box, Text } from 'rn-design-system'

const ToggleRoomFeatured = ({ roomName, featured }) => {
  const firebase = useFirebase()
  const [show, setShow] = useState(false)

  const toggle = async () => {
    if (roomName?.length > 0) {
      await firebase.room.toggleFeatured(roomName, !featured)
      setShow(false)
    }
  }

  return (
    <>
      {!show ? (
        <Button onClick={() => setShow(true)}>
          {featured ? 'unfavourite' : 'favourite'}
        </Button>
      ) : (
        <Alignment alignItems="flex-end">
          <Box mr={2}>
            <Text>you sure?</Text>
          </Box>
          <Box mr={2}>
            <Button onClick={() => setShow(false)}>nah</Button>
          </Box>
          <Button onClick={() => toggle()}>do it</Button>
        </Alignment>
      )}
    </>
  )
}

ToggleRoomFeatured.propTypes = {
  roomName: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
}

export default ToggleRoomFeatured
