import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Box, Alignment, Text } from 'rn-design-system'
import { useFirebase } from 'firebase-utils'
import ProgressBar from './Progress'

const Dropzone = ({ onComplete, disabled, accept, folder }) => {
  const { storage, error } = useFirebase()
  const [start, setStart] = useState(false)
  const [finish, setFinish] = useState(false)

  const onDrop = useCallback(
    async (blobsOrFiles) => {
      try {
        setFinish(false)
        setStart(true)
        blobsOrFiles.forEach(async (blobOrFile, index) => {
          try {
            const finished = (fileName) => {
              setFinish(true)
              setStart(false)

              if (onComplete && typeof onComplete === 'function') {
                onComplete(fileName)
              }
            }
            await storage.uploadBlobOrFile(blobOrFile, finished, folder)
          } catch (uploadErr) {
            await error.insert(
              `Error uploading file ${index}: ${uploadErr?.message}`
            )
          }
        })
      } catch (err) {
        await error.insert(`Error uploading files: ${err?.message}`)
      }
    },
    [storage, onComplete, error, folder]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Alignment flexDirection="column">
      <Box {...getRootProps()} border="1px dashed" p={3}>
        <input
          {...getInputProps()}
          multiple={false}
          disabled={disabled}
          accept={accept}
        />
        <Text color={disabled ? 'tertiary' : undefined}>
          drag n drop some files here, or click to select files
        </Text>
      </Box>
      <ProgressBar start={start} finish={finish} />
    </Alignment>
  )
}

Dropzone.propTypes = {
  onComplete: PropTypes.func,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  folder: PropTypes.string,
}

Dropzone.defaultProps = {
  onComplete: null,
  disabled: false,
  accept: 'image/*',
  folder: null,
}

export default Dropzone
