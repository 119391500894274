import React from 'react'
import { Alignment, Text, Box } from 'rn-design-system'

const Description = () => (
  <Alignment flexDirection="column">
    <Box mb={3}>
      <Text variant={Text.variants.large}>why</Text>
      <Text>
        syncers is here for you to curate and connect with each other. what we
        listening to next?
      </Text>
    </Box>
    <Box mb={3}>
      <Text variant={Text.variants.large}>what</Text>
      <Text>not a radio station. curated by our listeners. come through.</Text>
    </Box>
    <Text variant={Text.variants.large}>how</Text>
    <Text>
      find a sick mix you want to listen to, drop the url on the site. all the
      syncers get a chance to vote for what we all listen to next. whichever mix
      gets the most votes will then play next. easy. don’t be disheartened if it
      doesn’t get played, chuck it in again.
    </Text>
  </Alignment>
)

export default Description
