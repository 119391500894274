import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import hasDocs from 'helpers/hasDocs'
import { Text, Alignment, Box, Link, Button } from 'rn-design-system'
import styled from 'styled-components'
import {
  useRoomContext,
  useSettingsContext,
} from 'context/room-context/RoomContext'
import NothingPlayed from 'components/shared/history/shared/nothing-played'

const HistoryList = ({ headerText, limit, usePagination }) => {
  return (
    <Alignment
      flexDirection="column"
      width="100%"
      alignItems="center"
      border="1px dashed"
    >
      <Alignment justifyContent="center" width="100%" p={2}>
        <Text>{headerText}</Text>
      </Alignment>
      <Box borderTop="1px dashed" width="100%">
        <HistoryListItems usePagination={usePagination} limit={limit} />
      </Box>
    </Alignment>
  )
}

HistoryList.propTypes = {
  headerText: PropTypes.string,
  limit: PropTypes.number,
  usePagination: PropTypes.bool,
}

HistoryList.defaultProps = {
  headerText: 'history',
  limit: 10,
  usePagination: false,
}

const reduceHistory = (docs) => {
  const reduced = docs.reduce(
    (a, room) => [
      ...a,
      {
        id: room.id,
        ...room.data(),
      },
    ],
    []
  )

  return reduced
}

const EllipsisLink = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const EllipsisText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const HistoryListItems = ({ limit, usePagination }) => {
  const { firestore } = useFirebase()
  const { room } = useRoomContext()
  const { settings, loading: settingsLoading } = useSettingsContext()
  const [collection, loading] = useCollection(
    firestore
      .collection('history')
      .where('room', '==', room.id)
      .orderBy('startTime', 'desc')
      .limit(limit)
  )

  const [history, setHistory] = useState([])
  const [lastPlaying, setLastPlaying] = useState('')
  const [canPaginate, setCanPaginate] = useState(false)

  const setRoomsList = useCallback(
    (docs, merge) => {
      const reduced = reduceHistory(docs)

      if (reduced.length > 0) {
        const last = reduced.slice(-1)[0]
        setLastPlaying(last.id)
      }
      setCanPaginate(docs.length === limit)

      if (merge) {
        setHistory((x) => [...x, ...reduced])
      } else {
        setHistory(reduced)
      }
    },
    [setLastPlaying, setCanPaginate, setHistory, limit]
  )

  useEffect(() => {
    if (hasDocs(collection)) {
      setRoomsList(collection.docs)
    }
  }, [collection, setRoomsList, limit])

  const getNext = () => {
    firestore
      .collection('history')
      .orderBy('startTime', 'desc')
      .startAfter(lastPlaying)
      .limit(limit)
      .get()
      .then((result) => {
        if (result.empty) {
          setCanPaginate(false)
        } else {
          setRoomsList(result.docs, true)
        }
      })
  }

  if (!loading && history.length <= 0) {
    return <NothingPlayed />
  }

  if (loading || settingsLoading) {
    return null
  }

  const HistoryText = settings?.switches?.historyClickable
    ? EllipsisLink
    : EllipsisText

  return (
    <Alignment mx={2} mb={2} flexDirection="column">
      {history.map((roomHistory) => (
        <Alignment key={roomHistory.id} mt={2} borderBottom="1px dashed">
          <HistoryText href={roomHistory.url} target="_blank">
            {roomHistory.name}
          </HistoryText>
        </Alignment>
      ))}
      {usePagination && canPaginate && (
        <Box mt={3}>
          <Button onClick={() => getNext()}>+ more</Button>
        </Box>
      )}
    </Alignment>
  )
}

HistoryListItems.propTypes = {
  limit: PropTypes.number,
  usePagination: PropTypes.bool,
}

HistoryListItems.defaultProps = {
  limit: 10,
  usePagination: false,
}

export default HistoryList
