import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase } from 'firebase-utils'
import { Button, Alignment, Box, Text } from 'rn-design-system'

const ToggleRoomActive = ({ roomName, active }) => {
  const firebase = useFirebase()
  const [show, setShow] = useState(false)

  const toggle = async () => {
    if (roomName?.length > 0) {
      await firebase.room.toggleActive(roomName, !active)
      setShow(false)
    }
  }

  return (
    <>
      {!show ? (
        <Button onClick={() => setShow(true)}>
          {active ? 'de-activate' : 'activate'}
        </Button>
      ) : (
        <Alignment alignItems="flex-end">
          <Box mr={2}>
            <Text>you sure?</Text>
          </Box>
          <Box mr={2}>
            <Button onClick={() => setShow(false)}>nah</Button>
          </Box>
          <Button onClick={() => toggle()}>do it</Button>
        </Alignment>
      )}
    </>
  )
}

ToggleRoomActive.propTypes = {
  roomName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

export default ToggleRoomActive
