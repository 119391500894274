import { firestore as firebaseFirestore } from 'firebase/app'

class Room {
  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  updateRoom = async (update) => {
    const roomBatch = this.firestore.batch()

    const room = await this.firestore.collection('rooms').doc(this.currentRoom)

    roomBatch.set(room, update, { merge: true })

    return roomBatch
  }

  init = async (roomName) => {
    const batch = this.firestore.batch()

    const room = await this.firestore.collection('rooms').doc(roomName)

    const newRoom = {
      name: roomName,
      active: false,
      featured: false,
      public: true,
      mix: {
        url: '',
        name: '',
        startTime: Date.now(),
      },
      radio: {
        url: '',
        name: '',
        live: false,
      },
    }

    batch.set(room, newRoom, { merge: true })

    const emptyUpNext = {
      name: '',
      url: '',
      votes: [],
      room: roomName,
    }

    const firstUpNext = await this.firestore
      .collection('upNext')
      .doc(`${roomName}-1`)
    const secondUpNext = await this.firestore
      .collection('upNext')
      .doc(`${roomName}-2`)
    const thirdUpNext = await this.firestore
      .collection('upNext')
      .doc(`${roomName}-3`)

    batch.set(firstUpNext, emptyUpNext)
    batch.set(secondUpNext, emptyUpNext)
    batch.set(thirdUpNext, emptyUpNext)

    await batch.commit()
  }

  toggleActive = async (roomName, active) => {
    const roomBatch = this.firestore.batch()

    const room = await this.firestore.collection('rooms').doc(roomName)

    roomBatch.set(room, { active }, { merge: true })

    return roomBatch.commit()
  }

  toggleFeatured = async (roomName, featured) => {
    const roomBatch = this.firestore.batch()

    const room = await this.firestore.collection('rooms').doc(roomName)

    roomBatch.set(room, { featured }, { merge: true })

    return roomBatch.commit()
  }

  updateNowPlaying = async (url, name) => {
    const now = Date.now()

    const batch = await this.updateRoom({
      mix: {
        name,
        url,
        startTime: now,
      },
    })

    if (url && name) {
      const insertIntoHistoryRef = this.firestore.collection('history').doc()

      batch.set(insertIntoHistoryRef, {
        name,
        url,
        startTime: now,
        room: this.currentRoom,
      })
    }

    return batch.commit()
  }

  clearNowPlaying = async () => {
    const batch = await this.updateRoom({
      mix: {
        name: '',
        url: '',
        startTime: 0,
      },
    })

    return batch.commit()
  }

  vote = async (upNextId, syncerId) => {
    return this.firestore
      .collection('upNext')
      .doc(upNextId)
      .update({
        votes: firebaseFirestore.FieldValue.arrayUnion(syncerId),
      })
  }

  unvote = async (upNextId, syncerId) => {
    return this.firestore
      .collection('upNext')
      .doc(upNextId)
      .update({
        votes: firebaseFirestore.FieldValue.arrayRemove(syncerId),
      })
  }

  addUpNext = (id, name, url) => {
    return this.firestore.collection('upNext').doc(id).set(
      {
        name,
        url,
        votes: [],
      },
      { merge: true }
    )
  }

  resetUpNext = () =>
    this.firestore
      .collection('upNext')
      .where('room', '==', this.currentRoom)
      .get()
      .then((result) => {
        result.forEach((next) =>
          next.ref.update({
            name: '',
            url: '',
            votes: [],
          })
        )
      })

  toggleRadioLive = async (live) => {
    const batch = await this.updateRoom({
      radio: {
        live,
      },
    })

    return batch.commit()
  }

  setRadioName = async (name) => {
    const batch = await this.updateRoom({
      radio: {
        name,
      },
    })

    return batch.commit()
  }

  setRadioStream = async (url) => {
    const batch = await this.updateRoom({
      radio: {
        url,
      },
    })

    return batch.commit()
  }

  setRadio = async (values) => {
    const batch = await this.updateRoom({
      radio: {
        ...values,
      },
    })

    return batch.commit()
  }

  delete = async (roomName) => {
    await this.firestore.collection('rooms').doc(roomName).delete()
    await this.firestore.collection('upNext').doc(`${roomName}-1`).delete()
    await this.firestore.collection('upNext').doc(`${roomName}-2`).delete()
    await this.firestore.collection('upNext').doc(`${roomName}-3`).delete()
  }
}

export default Room
