import React from 'react'
import PropTypes from 'prop-types'
import Empty from 'components/shared/empty'
import StandardMix from './standard-mix'
import MixcloudMix from './mixcloud-mix'

const MixPlayer = ({ mix, hidden }) => {
  if (mix?.url?.includes('mixcloud.com')) {
    return <MixcloudMix key={mix?.url} mix={mix} />
  }

  return <StandardMix key={mix?.url} mix={mix} hidden={hidden} />
}

MixPlayer.propTypes = {
  mix: PropTypes.shape({
    url: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
  hidden: PropTypes.bool,
}

MixPlayer.defaultProps = {
  hidden: false,
}

const Mix = ({ mix, hidden }) => (
  <>
    {mix?.url ? (
      <MixPlayer mix={mix} hidden={hidden} />
    ) : (
      <Empty>
        <>
          get something on&nbsp;
          <span role="img" aria-label="point down emoji">
            👇
          </span>
        </>
      </Empty>
    )}
  </>
)

Mix.propTypes = {
  mix: PropTypes.shape({
    url: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
  hidden: PropTypes.bool,
}

Mix.defaultProps = {
  hidden: false,
}

export default Mix
