import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Editable, Input } from 'rn-design-system'
import Loading from 'components/shared/loading'
import { useFirebase } from 'firebase-utils'

const EditableInput = ({ value, placeholder, onComplete, required }) => {
  const { error } = useFirebase()
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(value)
  const inputRef = useRef()

  const handleComplete = async () => {
    if (required && !text) {
      setText(value)
    } else if (value !== text) {
      try {
        setLoading(true)
        await onComplete(text)
      } catch (err) {
        await error.insert(`error editing input: ${err?.message}`)
        setText(value)
      }

      setLoading(false)
    }
  }

  return (
    <>
      <Editable
        text={text}
        placeholder={placeholder}
        childRef={inputRef}
        type="input"
        onComplete={handleComplete}
      >
        <Input
          ref={inputRef}
          type="text"
          name="text"
          placeholder={placeholder}
          value={text}
          onChange={(e) => setText(e.target.value)}
          maxLength={100}
        />
      </Editable>
      <Loading loading={loading} ml={1} />
    </>
  )
}

EditableInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onComplete: PropTypes.func,
  required: PropTypes.bool,
}

EditableInput.defaultProps = {
  value: '',
  placeholder: 'set',
  onComplete: () => ({}),
  required: false,
}

export default EditableInput
