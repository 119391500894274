import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Alignment, Text } from 'rn-design-system'
import { useTheme } from 'styled-components'
import ReactModal from 'react-modal'

const Modal = ({ children, buttonText, title, closeText }) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>{buttonText}</Button>
      <ReactModal
        style={theme.modal}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <Alignment height="100%" flexDirection="column">
          <Alignment justifyContent="space-between" mb={2}>
            <Text variant={Text.variants.xLarge}>{title}</Text>
            <Button onClick={() => setIsOpen(false)}>{closeText}</Button>
          </Alignment>
          {children}
        </Alignment>
      </ReactModal>
    </>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string,
  closeText: PropTypes.string,
}

Modal.defaultProps = {
  title: '',
  closeText: '-',
}

export default Modal
