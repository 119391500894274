import { useFirebase } from 'firebase-utils'
import { useState, useEffect } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

const useAdmins = (roomName) => {
  const [admins, setAdmins] = useState({
    curators: [],
    managers: [],
  })
  const firebase = useFirebase()

  const [document, loading] = useDocument(
    firebase.firestore.doc(`admins/${roomName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (!loading) {
      if (document?.exists) {
        const result = document.data()

        setAdmins(result)
      }
    }
  }, [firebase.settings, document, setAdmins, roomName, loading])

  return { loading, admins }
}

export default useAdmins
