import React from 'react'
import PropTypes from 'prop-types'
import { Alignment } from 'rn-design-system'
import useReactionsCollection from 'hooks/useReactionsCollection'
import ReactionButton from './components/reaction-button'

const Reactions = ({ name }) => {
  const { reactions, loading } = useReactionsCollection()

  if (loading) {
    return null
  }

  return (
    reactions &&
    reactions.length > 0 && (
      <Alignment
        width="100%"
        justifyCOntent="space-between"
        borderTop="1px dashed"
        borderLeft="1px dashed"
        borderRight="1px dashed"
      >
        {reactions.map((reaction) => (
          <ReactionButton name={name} key={reaction.id} reaction={reaction} />
        ))}
      </Alignment>
    )
  )
}

Reactions.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Reactions
