import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import ReactModal from 'react-modal'
import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie'
import { brutal } from 'themes'
import Routes from 'app/Routes'
import { FirebaseContext, Firebase } from 'firebase-utils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import GlobalFonts from './GlobalFonts'

ReactModal.setAppElement('#root')

const App = () => (
  <Router>
    <CookiesProvider>
      <FirebaseContext.Provider value={new Firebase()}>
        <ThemeProvider theme={brutal}>
          <GlobalFonts />
          <GlobalStyle />
          <Helmet>
            <title>syncers worldwide | how far</title>
            <link
              href="https://fonts.googleapis.com/css?family=IBM+Plex+Mono"
              rel="stylesheet"
            />
          </Helmet>
          <Normalize />
          <Routes />
          <ToastContainer toastClassName="toast" hideProgressBar />
        </ThemeProvider>
      </FirebaseContext.Provider>
    </CookiesProvider>
  </Router>
)

const GlobalStyle = createGlobalStyle`
    html,
    body,
    body > div:first-child,
    div#__next,
    div#__next > div,
    div#__next > div > div {
      height: 100%;
    }

    html,
    body {
      max-width: 100%;
      box-sizing: border-box;
      ${({ theme }) => theme.global};
    }
  
    * {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
    }

    ul{
      list-style-type: none;
    }

    .toast {
      background: black !important;
      color: white !important;
      border: 1px dashed white !important;
    }
`

export default App
