import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import isEmpty from 'lodash/isEmpty'
import { Box } from 'rn-design-system'

const Image = ({ src, alt, circular, aspect, srcSet, sizes, ...props }) => (
  <Box display="inline-block" {...props}>
    <ProgressiveImage
      src={src}
      placeholder=""
      srcSetData={{ srcSet: srcSet || src, sizes }}
    >
      {(innerSrc, loading, srcSetData) =>
        loading ? (
          <Placeholder aspect={aspect} circular={circular} />
        ) : (
          <InnerImage
            src={innerSrc}
            srcSet={!isEmpty(srcSetData) && srcSetData.srcSet}
            sizes={!isEmpty(srcSetData) && srcSetData.sizes}
            alt={alt}
            circular={circular}
          />
        )
      }
    </ProgressiveImage>
  </Box>
)

Image.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.arrayOf(PropTypes.string),
  sizes: PropTypes.string,
  alt: PropTypes.string,
  circular: PropTypes.bool,
  aspect: PropTypes.string,
}

Image.defaultProps = {
  circular: false,
  sizes: '100%',
  aspect: '56.25%',
  srcSet: [],
  alt: '',
}

const InnerImage = styled.img`
  width: 100%;
  ${(props) => props.circular && 'border-radius: 50%'}
`

const Placeholder = styled.div`
  width: 100%;
  height: auto;
  ${(props) => props.aspect && `padding-bottom: ${props.aspect}`};
  ${(props) => props.circular && 'border-radius: 50%'};
  background-color: ${(props) => props.theme.colors.accent};
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes color {
    0% {
      background-color: ${(props) => props.theme.colors.accent};
    }
    50% {
      background-color: ${(props) => props.theme.colors.tertiary};
    }
    100% {
      background-color: ${(props) => props.theme.colors.accent};
    }
  }
`

export default Image
