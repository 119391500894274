import React from 'react'
import { useFirebase } from 'firebase-utils'
import HiddenForm from 'components/generic/hidden-form'

const CreatePlaylist = () => {
  const roomNameLimit = process.env.REACT_APP_ROOM_NAME_LIMIT
  const { playlists, error } = useFirebase()

  const create = async ({ name }) => {
    try {
      if (name && name.length <= roomNameLimit) {
        await playlists.addPlayist({ name })
      }
    } catch (err) {
      await error.insert(`error creating playlist: ${err?.message}`)
    }
  }

  return (
    <HiddenForm
      type="text"
      name="name"
      placeholder="playlist name"
      onSubmit={create}
      buttonText="add playlist"
      horizontal
    />
  )
}

export default CreatePlaylist
