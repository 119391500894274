import { useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import { useHeadingContext, actions } from 'context/heading-context'

const useRoom = (roomName) => {
  const firebase = useFirebase()
  const { dispatch } = useHeadingContext()

  const [currentRoom, loading] = useDocument(
    firebase.firestore.doc(`rooms/${roomName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  const [room, setRoom] = useState({
    exists: false,
    name: '',
    active: false,
    mix: {},
    radio: {},
  })

  useEffect(() => {
    if (!loading) {
      firebase.setRoom(roomName)
      if (currentRoom.exists) {
        const data = currentRoom.data()

        if (data?.active) {
          dispatch({
            type: actions.SET_HEADING,
            payload: {
              roomName: data?.name,
              roomLink: `/rooms/${currentRoom.id}`,
            },
          })

          setRoom({
            exists: true,
            id: currentRoom.id,
            ...data,
          })
        }
      } else {
        setRoom({ exists: false })
      }
    }
  }, [currentRoom, setRoom, roomName, firebase, loading, dispatch])

  return { loading, room }
}

export default useRoom
