import { useCallback } from 'react'
import { useUserContext } from 'context/user-context'
import axios from 'axios'
import { useRoomContext } from 'context/room-context'
import { useFirebase } from 'firebase-utils'

const API_URL = process.env.REACT_APP_SERVER_URL

const REQUEST_METHODS = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
}

const getHeaders = (token, roomId) => ({
  Authorization: `Bearer ${token}`,
  room: roomId,
  'Access-Control-Allow-Origin': '*',
})

const getRequest = ({ path, method, token, roomId = '', data = {} }) => {
  const headers = getHeaders(token, roomId)

  switch (method) {
    case REQUEST_METHODS.POST:
      return () =>
        axios.post(`${API_URL}${path}`, data, {
          headers,
        })
    case REQUEST_METHODS.PUT:
      return () =>
        axios.put(`${API_URL}${path}`, data, {
          headers,
        })
    case REQUEST_METHODS.GET:
      return () =>
        axios.get(`${API_URL}${path}`, {
          headers,
        })
    default:
      return () => ({})
  }
}

const useRequest = (path, method = REQUEST_METHODS.POST) => {
  const { error } = useFirebase()
  const { user } = useUserContext()
  const {
    room: { id },
  } = useRoomContext()

  const requestFn = useCallback(
    async (data) => {
      let result = {}
      try {
        const token = await user?.getIdToken()

        const request = getRequest({ path, method, token, roomId: id, data })

        const response = await request()

        result = response
      } catch (err) {
        await error.insert(`error calling ${method} ${path}: ${err?.message}`)
        result = {
          error: `error response from ${method} to ${path}, ${err?.message}`,
        }
      }

      return result
    },
    [path, method, user, id, error]
  )

  return requestFn
}

export { useRequest as default, REQUEST_METHODS }
