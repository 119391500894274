const COLORS = {
  dark: {
    primary: '#1e09e3',
    secondary: '#cbf114',
  },
  light: {
    primary: '#1e09e3',
    secondary: '#cbf114',
  },
}

export default COLORS
