import React from 'react'
import { Alignment, Box, Text } from 'rn-design-system'
import Dropzone from 'components/generic/dropzone'
import FilesGrid from './FilesGrid'
import useFiles from './useFiles'

const MAX_FILES = 10

const FileManagement = () => {
  const { files, loading, error, refetch } = useFiles()

  return (
    <Alignment flexDirection="column" mt={3}>
      <Box mb={2}>
        <Text variant={Text.variants.large}>
          files ({files.length}/{MAX_FILES}, images &amp; gifs)
        </Text>
      </Box>
      <Dropzone onComplete={refetch} disabled={files?.length === MAX_FILES} />
      <Box mt={1}>
        <FilesGrid
          files={files}
          loading={loading}
          error={error}
          refetch={refetch}
        />
      </Box>
    </Alignment>
  )
}

export default FileManagement
