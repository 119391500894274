import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Box, Text } from 'rn-design-system'
import DescriptionSettingsForm from './description-settings-form'

const DescriptionSettings = ({ description }) => (
  <Alignment flexDirection="column">
    <Box mb={2}>
      <Text variant={Text.variants.large}>description</Text>
    </Box>
    <Box mb={2}>
      <Text color={description ? undefined : 'tertiary'}>
        {description || 'set a description below'}
      </Text>
    </Box>
    <DescriptionSettingsForm description={description} />
  </Alignment>
)

DescriptionSettings.propTypes = {
  description: PropTypes.string,
}

DescriptionSettings.defaultProps = {
  description: '',
}

export default DescriptionSettings
