import React from 'react'
import PropTypes from 'prop-types'
import { Alignment, Text } from 'rn-design-system'

const Empty = ({ children, ...props }) => (
  <Alignment
    justifyContent="center"
    p={{ s: 5, m: 6 }}
    border="1px dashed"
    width="100%"
    {...props}
  >
    <Text variant={Text.variants.xLarge}>{children}</Text>
  </Alignment>
)

Empty.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Empty
