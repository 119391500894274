import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useFirebase } from 'firebase-utils'
import hasDocs from 'helpers/hasDocs'
import { Text, Alignment, Box, Button, Link } from 'rn-design-system'
import styled from 'styled-components'
import {
  useRoomContext,
  useSettingsContext,
} from 'context/room-context/RoomContext'
import NothingPlayed from 'components/shared/history/shared/nothing-played'

const HistoryTicker = ({ headerText, limit }) => {
  return (
    <Alignment
      flexDirection="column"
      width="100%"
      alignItems="center"
      border="1px dashed"
    >
      <Alignment justifyContent="center" width="100%" p={2}>
        <Text>{headerText}</Text>
      </Alignment>
      <Box borderTop="1px dashed" width="100%">
        <HistoryList limit={limit} />
      </Box>
    </Alignment>
  )
}

HistoryTicker.propTypes = {
  headerText: PropTypes.string,
  limit: PropTypes.number,
}

HistoryTicker.defaultProps = {
  headerText: 'history',
  limit: 10,
}

const reduceHistory = (docs) => {
  const reduced = docs.reduce(
    (a, room) => [
      ...a,
      {
        id: room.id,
        ...room.data(),
      },
    ],
    []
  )

  return reduced
}

const formatHistory = (history) => {
  if (history.length === 0) {
    return history
  }
  const repeatedArr = [].concat(
    ...Array(Math.ceil(10 / history.length)).fill(history)
  )
  const array = repeatedArr.slice(0, 10)
  array.push(array[0])
  return array
}

const EllipsisLink = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: rgb(255, 255, 255);
  background-color: transparent;

  &:hover {
    color: rgb(255, 255, 255);
    background-color: transparent;
  }
`
const EllipsisText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const HistoryList = ({ limit }) => {
  const { firestore } = useFirebase()
  const { room } = useRoomContext()
  const { settings, loading: settingsLoading } = useSettingsContext()
  const [collection, loading] = useCollection(
    firestore
      .collection('history')
      .where('room', '==', room.id)
      .orderBy('startTime', 'desc')
      .limit(limit)
  )

  const [history, setHistory] = useState([])

  const setHistoryList = useCallback(
    (docs, merge) => {
      const reduced = reduceHistory(docs)

      if (merge) {
        setHistory(formatHistory((x) => [...x, ...reduced]))
      } else {
        setHistory(formatHistory(reduced))
      }
    },
    [setHistory]
  )

  useEffect(() => {
    if (hasDocs(collection)) {
      setHistoryList(collection.docs)
    }
  }, [collection, setHistoryList, limit])

  if (!loading && history.length <= 0) {
    return <NothingPlayed />
  }

  if (loading || settingsLoading) {
    return null
  }

  const HistoryText = settings?.switches?.historyClickable
    ? EllipsisLink
    : EllipsisText

  return (
    <TickerScroll>
      <TickerList>
        {history.map((historyItem, index) => {
          return (
            <li key={index}>
              <HistoryButton>
                <HistoryText href={historyItem.url} target="_blank">
                  {historyItem.name}
                </HistoryText>
              </HistoryButton>
            </li>
          )
        })}
      </TickerList>
    </TickerScroll>
  )
}

HistoryList.propTypes = {
  limit: PropTypes.number,
}

HistoryList.defaultProps = {
  limit: 10,
}

const HistoryButton = styled(Button)`
  height: 38px;
  border: 0px;
  width: 100%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
`
const TickerScroll = styled.div`
  background: none;
  height: 35px;
  width: 100%;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
`

const TickerList = styled.ul`
  -webkit-animation: tickr 30s cubic-bezier(1, 0, 0.5, 0) infinite;
  -moz-animation: tickr 30s cubic-bezier(1, 0, 0.5, 0) infinite;
  -ms-animation: tickr 30s cubic-bezier(1, 0, 0.5, 0) infinite;
  animation: tickr 30s cubic-bezier(1, 0, 0.5, 0) infinite;

  &:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused;
  }

  @keyframes tickr {
    0% {
      margin-top: 0px;
    }
    9% {
      margin-top: -38px;
    }
    18% {
      margin-top: -76px;
    }
    27% {
      margin-top: -114px;
    }
    36% {
      margin-top: -152px;
    }
    45% {
      margin-top: -190px;
    }
    54% {
      margin-top: -228px;
    }
    63% {
      margin-top: -266px;
    }
    72% {
      margin-top: -304px;
    }
    81% {
      margin-top: -342px;
    }
    90% {
      margin-top: -342px;
    }
    99% {
      margin-top: -380px;
    }
    99.99% {
      margin-top: -380px;
    }
    100% {
      margin-top: 0px;
    }
  }
`

export default HistoryTicker
