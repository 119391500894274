import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase } from 'firebase-utils'
import { Button, Alignment, Box, Text } from 'rn-design-system'

const DeleteRoom = ({ roomName }) => {
  const firebase = useFirebase()
  const [show, setShow] = useState(false)

  const deleteRoom = async () => {
    if (roomName?.length > 0) {
      await firebase.delete(roomName)
    }
  }

  return (
    <>
      {!show ? (
        <Button onClick={() => setShow(true)}>delete</Button>
      ) : (
        <Alignment alignItems="flex-end">
          <Box mr={2}>
            <Text>you sure?</Text>
          </Box>
          <Box mr={2}>
            <Button onClick={() => setShow(false)}>nah</Button>
          </Box>
          <Button onClick={() => deleteRoom()}>do it</Button>
        </Alignment>
      )}
    </>
  )
}

DeleteRoom.propTypes = {
  roomName: PropTypes.string.isRequired,
}

export default DeleteRoom
