import React from 'react'
import { Alignment, Grid, Link, Text, Box } from 'rn-design-system'
import Layout, { Areas } from 'components/generic/layout'
import Empty from 'components/shared/empty'

const DoesntExist = () => (
  <Layout>
    <Grid.Area area={Areas.Center}>
      <Alignment mt={3} flexDirection="column" alignItems="center">
        <Empty>nothing here, sorry</Empty>
        <Box mt={2}>
          <Text>
            if you&apos;d like this room, contact&nbsp;
            <Link underline target="_blank" href="mailto:syncersclub@gmail.com">
              syncers worldwide
            </Link>
          </Text>
        </Box>
      </Alignment>
    </Grid.Area>
  </Layout>
)

export default DoesntExist
