import React from 'react'
import PropTypes from 'prop-types'
import Login from 'components/shared/login'
import { useUserContext } from './UserContext'

const AuthenticatedComponent = ({ children }) => {
  const { loading, authenticated } = useUserContext()

  if (loading) {
    return null
  }

  return authenticated ? <>{children}</> : <Login />
}

AuthenticatedComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatedComponent
