const getRoomId = (room) => {
  if (!room || (room && room.length > process.env.REACT_APP_ROOM_NAME_LIMIT)) {
    return 'home'
  }

  return room.replace(/\s+/g, '-').toLowerCase().trim()
}

const getRoomName = (id) => id?.replace(/-/g, ' ')

export { getRoomId, getRoomName }
