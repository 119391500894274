import React from 'react'
import { Box } from 'rn-design-system'
import BouncingLogo from 'components/shared/bouncing-logo'
import { useSettingsContext } from 'context/room-context'
import styled, { createGlobalStyle } from 'styled-components'

const RoomVisuals = () => {
  const { settings, loading } = useSettingsContext()

  if (loading) {
    return null
  }

  return (
    <>
      <Override
        cursor={settings?.visuals?.cursor}
        font={settings?.pageStyle?.font}
        fontAdjust={settings?.pageStyle?.fontAdjust}
        textShadow={settings?.pageStyle?.textShadow}
      />
      {settings?.visuals?.background && (
        <>
          <BackgroundImage image={settings?.visuals?.background} />
          <BackgroundOverlay
            opacity={settings?.pageStyle?.backgroundOpacity || 0.7}
          />
        </>
      )}
      <Box display={{ s: 'none', m: 'block' }}>
        <BouncingLogo src={settings?.visuals?.logo} size={240} />
      </Box>
      <Box display={{ m: 'none' }}>
        <BouncingLogo src={settings?.visuals?.logo} size={140} />
      </Box>
    </>
  )
}

const Override = createGlobalStyle`
    html,
    body {
      font-family: ${({ font }) => font};
      font-size-adjust:  ${({ fontAdjust }) => fontAdjust};
      text-shadow: ${({ textShadow }) => textShadow};
      cursor: url(${({ cursor }) => cursor}), auto;
    }
`

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  background: black;
  opacity: ${({ opacity }) => opacity};
`

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background: url(${({ image }) => image});
  background-size: contain;
  background-position: center;
`

export default RoomVisuals
