import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Input, Button, Text } from 'rn-design-system'
import { useForm } from 'react-hook-form'

const StreamInput = ({ type, name, placeholder, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  })
  const [formErrors, setFormErrors] = useState()

  const set = async (values) => {
    try {
      await onSubmit(values)
    } catch (error) {
      setFormErrors(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(set)}>
      <Alignment alignItems="flex-end" width="100%">
        <Alignment
          borderLeft="1px dashed"
          borderTop="1px dashed"
          height="40px"
          flexDirection="column"
          justifyContent="flex-end"
          width="100%"
        >
          <Input
            required
            type={type}
            placeholder={placeholder}
            name={name}
            ref={register({
              required: true,
              maxLength: 80,
            })}
            textVariant={Text.variants.medium}
            p={1}
            autoComplete="off"
          />
        </Alignment>
        <Button textVariant={Text.variants.large} type="submit" underline>
          &gt;
        </Button>
      </Alignment>
      {(errors.email || errors.password || formErrors) && (
        <Text>something went wrong, please try again</Text>
      )}
    </form>
  )
}

StreamInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default StreamInput
