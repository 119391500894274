import React from 'react'
import PropTypes from 'prop-types'
import { Route, useParams } from 'react-router-dom'
import { Box } from 'rn-design-system'
import { getRoomId } from 'helpers/getRoom'
import Heading from 'components/shared/heading'
import { RoomAdminProvider } from 'context/room-admin-context'
import Footer from 'components/shared/footer'
import { MixProvider } from 'context/mix-context'
import { RoomProvider } from './RoomContext'
import RoomVisuals from './room-visuals'

const RoomRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <RoomComponent component={component} {...props} />}
  />
)

RoomRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

const RoomComponent = ({ component, ...props }) => {
  const { roomName } = useParams()

  return (
    <RoomProvider roomName={getRoomId(roomName)}>
      <RoomAdminProvider roomName={getRoomId(roomName)}>
        <MixProvider>
          <Content component={component} {...props} />
        </MixProvider>
      </RoomAdminProvider>
    </RoomProvider>
  )
}

RoomComponent.propTypes = {
  component: PropTypes.func.isRequired,
}

const Content = ({ component: Component, ...props }) => (
  <>
    <RoomVisuals />
    <Box position="relative" zIndex={1}>
      <Heading />
      <Component {...props} />
      <Footer />
    </Box>
  </>
)

Content.propTypes = {
  component: PropTypes.func.isRequired,
}

export default RoomRoute
