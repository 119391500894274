import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { differenceInSeconds } from 'date-fns'
import { Alignment } from 'rn-design-system'
import { useMixContext, actions } from 'context/mix-context'
import { VolumeMute, VolumeUp } from '@styled-icons/fa-solid'
import MuteButton from './MuteButton'

const resetVolume = (player) => {
  if (player?.getInternalPlayer) {
    const internalPlayer = player.getInternalPlayer()

    if (
      internalPlayer?.setVolume &&
      typeof internalPlayer?.setVolume === 'function'
    ) {
      internalPlayer.setVolume(0)
    }
  }
}

const setTime = (player, startTime, dispatch) => {
  if (player && startTime) {
    resetVolume(player)
    const duration = player.getDuration()

    const timeElapsed = differenceInSeconds(Date.now(), new Date(startTime))

    const finished = timeElapsed >= duration

    const seconds = finished ? duration : timeElapsed
    player.seekTo(seconds, 'seconds')

    dispatch({
      type: actions.SET_IS_PLAYING,
      payload: !finished,
    })
    dispatch({
      type: actions.SET_HAS_FINISHED,
      payload: finished,
    })
  }
}

const checkPlayingAndVolume = (hidden, muted, player) => {
  if (hidden) {
    player.seekTo(0, 'seconds')
  }

  if (hidden || muted) {
    resetVolume(player)
  }
}

const unmuteAndSetTime = (muted, setMuted, player, startTime, dispatch) => {
  if (muted) {
    setTime(player, startTime, dispatch)
  }
  setMuted(!muted)
}

const StandardMix = ({ mix, hidden }) => {
  const [muted, setMuted] = useState(!hidden)
  const ref = useRef()
  const {
    state: { isPlaying },
    dispatch,
  } = useMixContext()

  const setHasFinished = (value) => {
    if (!hidden) {
      dispatch({
        type: actions.SET_HAS_FINISHED,
        payload: value,
      })
    }
  }

  useEffect(() => {
    if (!hidden) {
      setTime(ref.current, mix.startTime, dispatch)
    }
  }, [hidden, mix.startTime, dispatch])

  return (
    <Alignment
      flexDirection="column"
      width={hidden ? '0px' : '100%'}
      height={hidden ? '0px' : '340px'}
      alignContent="flex-start"
    >
      <ReactPlayer
        ref={ref}
        url={mix.url}
        playing={isPlaying}
        onReady={() => setTime(ref.current, mix.startTime, dispatch)}
        onEnded={() => setHasFinished(true)}
        onProgress={() => checkPlayingAndVolume(hidden, muted, ref.current)}
        muted={muted || hidden}
        volume={muted || hidden ? 0 : 1}
        width={hidden ? '0px' : '100%'}
        height={hidden ? '0px' : '300px'}
        loop={hidden}
        config={{
          options: {
            single_active: false,
            auto_play: true,
          },
        }}
      />
      <MuteButton
        display={hidden ? 'none' : 'block'}
        onClick={() =>
          unmuteAndSetTime(
            muted,
            setMuted,
            ref.current,
            mix.startTime,
            dispatch
          )
        }
        shouldBlink={muted}
      >
        {muted ? <VolumeMute size={20} /> : <VolumeUp size={20} />}
      </MuteButton>
    </Alignment>
  )
}

StandardMix.propTypes = {
  mix: PropTypes.shape({
    url: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
  hidden: PropTypes.bool,
}

StandardMix.defaultProps = {
  hidden: false,
}

export default StandardMix
