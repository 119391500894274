import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'rn-design-system'
import { useSyncerContext } from 'context/syncer-context'
import useReactionsCollection from 'hooks/useReactionsCollection'
import { useFirebase } from 'firebase-utils'
import styled from 'styled-components'
import { useSettingsContext } from 'context/room-context'
import Messages from './components/messages'
import ChatInput from './components/ChatInput'
import ChangeName from './components/ChangeName'
import Reactions from './components/reactions'

const Chat = () => {
  const { reaction } = useFirebase()
  const syncer = useSyncerContext()
  const { reactions, loading } = useReactionsCollection()
  const { settings, loading: settingsLoading } = useSettingsContext()
  const [name, setName] = useState(syncer.name)
  const [backgroundUrl, setBackgroundUrl] = useState('')

  useEffect(() => {
    if (!loading) {
      const background = reactions.find(
        (x) =>
          x?.action?.type === reaction.REACTION_TYPES.BACKGROUND_IMAGE &&
          x?.active
      )

      if (background?.action?.url) {
        setBackgroundUrl(background.action.url)
      } else {
        setBackgroundUrl('')
      }
    }
  }, [reactions, reaction.REACTION_TYPES.BACKGROUND_IMAGE, loading])

  useEffect(() => {
    setName(syncer.name)
  }, [syncer.name])

  if (settingsLoading || loading) {
    return null
  }

  if (!settingsLoading && !settings.switches.showChat) {
    return null
  }

  return (
    <>
      <Box width="100%" position="relative">
        <BackgroundImage image={backgroundUrl} />
        <BackgroundOverlay />
        <Box position="relative" width="100%">
          <ChatBox name={name} />
        </Box>
      </Box>
      <ChangeName setName={setName} />
    </>
  )
}

const ChatBox = ({ name }) => (
  <>
    <Box width="100%" border="1px dashed" borderBottom="none" p={1}>
      <Text variant={Text.variants.small}>{name}</Text>
    </Box>
    <Messages />
    <Reactions name={name} />
    <ChatInput name={name} />
  </>
)

ChatBox.propTypes = {
  name: PropTypes.string.isRequired,
}

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  background: black;
  opacity: 0.5;
`

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  background: url(${({ image }) => image});
  background-size: contain;
  background-position: center;
`

export default Chat
