import { firestore as firebaseFirestore } from 'firebase/app'

class Reaction {
  #collection = 'errors'

  constructor(firestore, currentRoom) {
    this.firestore = firestore
    this.currentRoom = currentRoom
  }

  setRoom = (name) => {
    this.currentRoom = name
  }

  insert = async (message) => {
    try {
      const error = {
        message: JSON.stringify(message),
        room: this.currentRoom,
        createdDate: firebaseFirestore.Timestamp.now(),
      }

      await this.firestore.collection(this.#collection).add(error)
    } catch (error) {
      console.log('error inserting error', error)
    }
  }
}

export default Reaction
