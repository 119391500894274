const DEFAULT_BOX_STYLE = {
  offset: 3,
  borders: {
    leftBottom: {
      height: 6,
      left: -5,
      bottom: -2,
    },
    leftTop: {
      height: 6,
      left: -4,
      top: -6,
    },
    rightTop: {
      height: 6,
      top: -6,
      right: 0,
    },
  },
}

const BOX_STYLES = [
  {
    offset: 23,
    borders: {
      leftBottom: {
        height: 23,
        left: -11,
        bottom: -4,
      },
      leftTop: {
        height: 23,
        left: -11,
        top: -21,
      },
      rightTop: {
        height: 23,
        top: -21,
        right: 6,
      },
    },
  },
  {
    offset: 23,
    borders: {
      leftBottom: {
        height: 23,
        left: -11,
        bottom: -4,
      },
      leftTop: {
        height: 23,
        left: -11,
        top: -21,
      },
      rightTop: {
        height: 23,
        top: -21,
        right: 6,
      },
    },
  },
  {
    offset: 13,
    borders: {
      leftBottom: {
        height: 13,
        left: -7,
        bottom: -3,
      },
      leftTop: {
        height: 13,
        left: -7,
        top: -12,
      },
      rightTop: {
        height: 13,
        top: -12,
        right: 2,
      },
    },
  },
]

export { DEFAULT_BOX_STYLE }
export default BOX_STYLES
